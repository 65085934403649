import { Modal } from '@rabbit/elements/shared-components';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { ModalAddEditFault } from './ModalTypes/ModalAddEditFault';
import { ModalAddEditFaultCategory } from './ModalTypes/ModalAddEditFaultCategory';
import { ModalAddEditIssueType } from './ModalTypes/ModalAddEditIssueType';
import { ModalAddEditPart } from './ModalTypes/ModalAddEditPart';
import { ModalAddEditVendable } from './ModalTypes/ModalAddEditVendable';
import { FileStorageContext } from '@rabbit/bizproc/react';
import { ModalAddEditCause } from './ModalTypes/ModalAddEditCause';
import { ModalAddEditVendableV2 } from './ModalTypes/ModalAddEditVendable.v2';

export enum ModalAddEditItemKind {
  products = 'products',
  parts = 'parts',
  faults = 'faults',
  causes = 'causes',
  faultsCategories = 'faults_categories',
  issueTypes = 'issueTypes',
}

export enum ModalAddEditItemMode {
  edit = 'edit',
  new = 'new',
}
export interface ModalAddEditItem {
  handleClose: () => void;
  handleDelete?: () => void;
  // This actually probably doesn't need to exist, review - dc
  handleSubmit?: () => void;
  itemDetails?: any; // todo: type this
  kind?: ModalAddEditItemKind;
  title?: string;
  mode?: ModalAddEditItemMode;
  itemId?: string;
}

function renderChildren({
  handleClose,
  handleDelete,
  handleSubmit,
  setLoading,
  itemDetails,
  kind,
  mode,
  itemId,
}: ModalAddEditItem & { setLoading: Dispatch<SetStateAction<boolean>> }) {
  if (kind === ModalAddEditItemKind.products) {
    return (
      <ModalAddEditVendableV2
        handleClose={handleClose}
        //handleDelete={handleDelete}
        vendableId={itemDetails?.docid}
        setLoading={setLoading}
      />
    );
  }

  if (kind === ModalAddEditItemKind.parts)
    return (
      <ModalAddEditPart
        handleClose={handleClose}
        itemDetails={itemDetails && itemDetails}
        handleDelete={handleDelete}
        setLoading={setLoading}
        // handleSubmit={() => void 0}
      />
    );

  if (kind === ModalAddEditItemKind.faults) {
    return (
      <ModalAddEditFault
        handleDelete={handleDelete}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        itemDetails={itemDetails && itemDetails}
        mode={mode || ModalAddEditItemMode.new}
        setLoading={setLoading}
      />
    );
  }

  if (kind === ModalAddEditItemKind.causes) {
    return (
      <ModalAddEditCause
        handleClose={handleClose}
        itemDetails={itemDetails && itemDetails}
        mode={mode || ModalAddEditItemMode.new}
        setLoading={setLoading}
      />
    );
  }

  if (kind === ModalAddEditItemKind.faultsCategories)
    return (
      <ModalAddEditFaultCategory
        handleClose={handleClose}
        itemDetails={itemDetails && itemDetails}
        mode={mode || ModalAddEditItemMode.new}
        setLoading={setLoading}
        // handleSubmit={() => void 0}
      />
    );

  if (kind === ModalAddEditItemKind.issueTypes) {
    return (
      <ModalAddEditIssueType
        handleDelete={handleDelete}
        handleClose={handleClose}
        // handleSubmit={handleSubmit}
        itemDetails={itemDetails && itemDetails}
        mode={mode || ModalAddEditItemMode.new}
        setLoading={setLoading}
      />
    );
  }

  return null;
}

export function ModalAddEditItem({
  handleClose,
  handleDelete,
  handleSubmit,
  itemDetails,
  kind,
  title,
  mode,
}: ModalAddEditItem) {
  const { unusedCompletedCleanup } = useContext(FileStorageContext) || {};
  const modalSettings = {
    title: title,
    handleClose: () => {
      // always clean up any unused completed uploads when the modal is closed
      unusedCompletedCleanup?.();
      handleClose();
    },
  };
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Modal
        className="h-fit rounded-md bg-white lg:w-[724px]"
        kind="generic"
        children={renderChildren({
          handleClose,
          handleDelete,
          itemDetails,
          kind,
          mode,
          handleSubmit,
          setLoading,
        })}
        settings={modalSettings}
        isLoading={loading}
      />
    </div>
  );
}

export default ModalAddEditItem;
