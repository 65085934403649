import { useEffect, useMemo, useState } from 'react';
import {
  DR_Consumer_Private,
  DR_Identity,
  DR_Identity_Private,
  DR_Manufacturer_Private,
  DR_Repairer_Private,
  DR_Retailer_Private,
  DR_Warrantor_Private,
  DR_WarrantyDealer_Private,
} from '../datatypes';
import { useMultipleFirestoreQueries } from '../document/use-multiple-firestore-queries';
import {
  Identity_EnsureIdentityExistsForAuthLikeObject,
  Identity_CreateConsumerPersonaForIdentity,
  Identity_CreateRepairerPersonaForIdentity,
} from '@rabbit/bizproc/client';
import { firebaseAuth } from '@rabbit/firebase/adapter-react';
import { DocumentData } from 'firebase/firestore';
import { Identity_SubscribeToChanges } from './subscription';

export * from './authentication';
export * from './subscription';

export async function getOrCreateIdentity() {
  // Get current auth details from firebase
  const authDetails = firebaseAuth.currentUser;
  if (!authDetails) throw new Error('Cannot get identity without a valid user');

  const identities = await Identity_EnsureIdentityExistsForAuthLikeObject(
    authDetails
  );

  Identity_SubscribeToChanges(identities.identity_private);

  return {
    identity: identities.identity,
    identity_private: identities.identity_private,
  };
}

export function useIdentity() {
  const authDetails = firebaseAuth.currentUser;
  const uid = authDetails?.uid;
  if (!uid) throw new Error("Can't get identity without a valid user");

  const myIdentity = useMultipleFirestoreQueries<DocumentData>({
    identity: { type: DR_Identity, docId: uid },
    identity_private: {
      type: DR_Identity_Private,
      docId: uid,
    },
  });
  const isAdmin =
    myIdentity.data.identity_private?.email === 'admin@iwarranty.co';

  return {
    ...myIdentity,
    uid,
    isAdmin,
  };
}

export function useMyConsumerPersona() {
  const identity = useIdentity();
  const consumerPersona = identity.data.identity_private?.persona?.consumer;
  // If Sage personas exist for identity, no consumer persona should be created.
  // todo: add more as necessary in the future - dc
  const repairerPersona = identity.data.identity_private?.persona?.repairer;
  const manufacturerPersona =
    identity.data.identity_private?.persona?.manufacturer;
  const [isCreating, setIsCreating] = useState(false);

  const persona = useMultipleFirestoreQueries({
    consumer_private: {
      type: DR_Consumer_Private,
      docId: consumerPersona || null,
    },
  });

  useEffect(() => {
    if (identity?.data?.identity_private && persona?.isFetched) {
      if (
        !consumerPersona &&
        !repairerPersona &&
        !manufacturerPersona &&
        !isCreating
      ) {
        setIsCreating(true);
        console.log('Generating consumer persona for identity');
        // Without wait and the flag above, this will sometimes be called multiple times
        (async () => {
          const res = await Identity_CreateConsumerPersonaForIdentity(
            identity?.data?.identity_private
          );
          return res;
        })()
          .then((res) => {
            if (res) setIsCreating(false);
          })
          .catch((e) => {
            setIsCreating(false);
            console.error(e);
          });
      }
    }
  }, [
    persona?.isFetched,
    identity.data.identity_private,
    identity.data.identity_private?.persona?.consumer,
  ]);

  if (repairerPersona || manufacturerPersona)
    return {
      ...persona,
      personaId: 'NOTALLOWED',
    };

  return { ...persona, personaId: consumerPersona || null };
}

/** Checks if identity has any/all of the personas used on the enterprise app (currently repairer and manufacturer)
 *  and returns an object with their data if they exist, or null if they don't
 */
export function useGetMySagePersonas() {
  const identity = useIdentity();

  // Check if user has any/all of the personas used on the enterprise app.
  const repairerPersonaId: string =
    identity.data.identity_private?.persona?.repairer;
  const manufacturerPersonaId: string =
    identity.data.identity_private?.persona?.manufacturer;
  const retailerPersonaId: string =
    identity.data.identity_private?.persona?.retailer;
  const dealerPersonaId: string =
    identity.data.identity_private?.persona?.warrantyDealer;
  const warrantorPersonaId: string =
    identity.data.identity_private?.persona?.warrantor;
  const isAdmin = identity.isAdmin;

  const queryStatus = useMultipleFirestoreQueries({
    retailer_private: {
      type: DR_Retailer_Private,
      docId: retailerPersonaId || null,
    },
    repairer_private: {
      type: DR_Repairer_Private,
      docId: repairerPersonaId || null,
    },
    manufacturer_private: {
      type: DR_Manufacturer_Private,
      docId: manufacturerPersonaId || null,
    },
    warrantyDealer_private: {
      type: DR_WarrantyDealer_Private,
      docId: dealerPersonaId || null,
    },
    warrantor_private: {
      type: DR_Warrantor_Private,
      docId: warrantorPersonaId || null,
    },
  });

  const {
    repairer_private,
    manufacturer_private,
    retailer_private,
    warrantyDealer_private,
    warrantor_private,
  } = queryStatus.data;

  const personaData = useMemo(
    () => ({
      repairerPersona: repairerPersonaId
        ? { repairer_private, personaId: repairerPersonaId }
        : null,
      manufacturerPersona: manufacturerPersonaId
        ? { manufacturer_private, personaId: manufacturerPersonaId }
        : null,
      retailerPersona: retailerPersonaId
        ? { retailer_private, personaId: retailerPersonaId }
        : null,
      warrantyDealerPersona: dealerPersonaId
        ? { warrantyDealer_private, personaId: dealerPersonaId }
        : null,
      warrantorPersona: warrantorPersonaId
        ? { warrantor_private, personaId: warrantorPersonaId }
        : null,
      queryStatus,
      isAdmin,
    }),
    [
      repairer_private,
      manufacturer_private,
      retailer_private,
      warrantyDealer_private,
      warrantor_private,
      isAdmin,
    ]
  );
  return personaData;
}

/** Checks if identity has any associated repairer persona. Returns it if true, creates and then returns it if false */
export function useMyRepairerPersona() {
  const identity = useIdentity();

  // Check if user already has a repairer persona associated to their identity.
  const repairerPersonaId = identity.data.identity_private?.persona?.repairer;

  const repairerPersona = useMultipleFirestoreQueries({
    repairer_private: {
      type: DR_Repairer_Private,
      docId: repairerPersonaId || null,
    },
  });

  useEffect(() => {
    if (identity.data.identity_private) {
      if (!identity.data.identity_private?.persona?.repairer) {
        void Identity_CreateRepairerPersonaForIdentity(
          identity.data.identity_private
        );
      }
    }
  }, [
    identity.data.identity_private,
    identity.data.identity_private?.persona?.repairer,
  ]);

  return { ...repairerPersona, personaId: repairerPersonaId || null };
}
