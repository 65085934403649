import fs from 'fs';
import path from 'path';
import { FBD_Veil } from '@rabbit/mixmaster/core';

// allowed partitions
const Partitions = {
  core: 1,
  cloud: 1,
};

export enum VeilKeys {
  CFG_TENANT_IS_MANUFACTURER = 'CFG_TENANT_IS_MANUFACTURER',
  CFG_COBRAND_NAME = 'CFG_COBRAND_NAME',
  CFG_COBRAND_FINISHING_MESSAGE = 'CFG_COBRAND_FINISHING_MESSAGE',
  CFG_COBRAND_EMAIL_MAIN_TEMPLATE = 'CFG_COBRAND_EMAIL_MAIN_TEMPLATE',
  CFG_COBRAND_EMAIL_SENDER = 'CFG_COBRAND_EMAIL_SENDER',
  CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE = 'CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE',
  EMAIL_SUBJECT_CLAIM_REGISTERED_WAITING_REVIEW = 'EMAIL_SUBJECT_CLAIM_REGISTERED_WAITING_REVIEW',
  EMAIL_SUBJECT_ITEM_REGISTERED_WITH_IWARRANTY = 'EMAIL_SUBJECT_ITEM_REGISTERED_WITH_IWARRANTY',
  EMAIL_SUBJECT_ACTIVATE_WARRANTY = 'EMAIL_SUBJECT_ACTIVATE_WARRANTY',
  EMAIL_SUBJECT_INVITED_TO_JOIN = 'EMAIL_SUBJECT_INVITED_TO_JOIN',
  CFG_DEFAULT_CURRENCY = 'CFG_DEFAULT_CURRENCY',
  CFG_USE_SHOPIFY_ORDER_NUMBER = 'CFG_USE_SHOPIFY_ORDER_NUMBER',
  TENANT_LINK = 'tenantLink',
  EMAIL_SUBJECT_STONE_REGISTRATION_THANK_YOU = 'EMAIL_SUBJECT_STONE_REGISTRATION_THANK_YOU',
  EMAIL_SUBJECT_VEHICLE_REGISTRATION = 'EMAIL_SUBJECT_VEHICLE_REGISTRATION',
  EMAIL_SUBJECT_EDIT_WARRANTY = 'EMAIL_SUBJECT_EDIT_WARRANTY',
  CFG_DEFAULT_PRODUCT_IMAGE = 'CFG_DEFAULT_PRODUCT_IMAGE',
}

export async function UploadAllVeilsToTheCloud() {
  // For now, purge all the veils

  // Go thru the veils folder and upload each veil
  // Get directory list of json files in ./veils

  const directoryPath = path.join(
    __dirname,
    '../../../libs/mixmaster/veils/src/veils'
  );

  console.debug('Scanning for veils in ' + directoryPath);

  const files = await fs.promises.readdir(directoryPath);
  const jsonFiles = files.filter((file) => path.extname(file) === '.json');

  jsonFiles.forEach((file) => {
    if (file.indexOf(' ') >= 0) {
      throw new Error(
        `Veil file ${file} contains spaces in its name. (no spaces allowed)`
      );
    }
  });

  console.debug(`JSON files in ${directoryPath}:`);
  console.debug(jsonFiles);

  for (const file of jsonFiles) {
    const filePath = path.join(directoryPath, file);
    const fileContent = await fs.promises.readFile(filePath, 'utf8');
    const veil = file.split('.json')[0];
    const body = JSON.parse(fileContent);

    console.debug(`Veil ${veil} loaded from ${filePath}`);
    console.debug(body);

    // check the body for partitions
    for (const partition in body) {
      if ((Partitions as any)[partition] !== 1) {
        throw new Error(
          `Veil ${veil} contains partition ${partition} which is not allowed.`
        );
      }

      const content = body[partition];
      // Make sure all content is string only
      for (const key in content) {
        if (typeof content[key] !== 'string') {
          throw new Error(
            `Veil ${veil} contains partition ${partition} with key ${key} which is not a string.`
          );
        }
      }

      // Pump it into firebase
      const doc = FBD_Veil.empty();
      doc.docid = partition + ':' + veil;
      doc.kv = content;
      await FBD_Veil.set(doc);
    }
  }
  console.debug('== DONE ==');
  process.exit(0);
}

import base from './veils/base.json';
export { base };
