import {
  Security_FindDirectChildrenWithAccess,
  Security_FindPeers,
} from '@rabbit/bizproc/client';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  DTRepairer_Public,
  FBD_Repairer_Public,
  Permissions,
} from '@rabbit/data/types';
import { useEffect, useState } from 'react';

export interface OptionShape {
  id: string;
  value: string;
  label: string;
}
// todo: eventually this should probably be a generic getPossibleValidCaseActors (or something) in the future
export function useGetRepairerPeers() {
  const { repairerPersona, warrantorPersona } = useGetMySagePersonas() || {};
  const [repairerPeerLoading, setRepairerPeerLoading] = useState(true);
  const [repairerPeer, setRepairerPeer] = useState<DTRepairer_Public[]>([]);

  useEffect(() => {
    const filterPeers = async (idArr: string[]) => {
      //Kick this off with the user's own id
      const promises: Promise<DTRepairer_Public | null>[] = [
        FBD_Repairer_Public.get(repairerPersona?.personaId ?? ''),
      ];
      idArr.forEach((id) => {
        promises.push(FBD_Repairer_Public.get(id));
      });

      const peerData = await Promise.all(promises);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const environment = import.meta.env.VITE_FIREBASE_MODE;
      const result =
        environment === 'LIVE'
          ? peerData.map((item) => {
              // If email ends in @iwarranty.co or @studiographene.com, don't include it
              if (
                item?.email &&
                !item?.email?.includes('@iwarranty.co') &&
                !item?.email?.includes('@studiographene.com')
              ) {
                return item;
              } else return null;
            })
          : peerData;

      return result;
    };
    const fetchPeers = async () => {
      let peers: string[] = [];

      if (
        repairerPersona?.personaId &&
        repairerPersona.repairer_private?.principals?.[0]
      ) {
        let repairerPeers: string[] = [];
        repairerPeers = await Security_FindPeers(repairerPersona.personaId, [
          Permissions.CasesEdit,
        ]);
        if (repairerPeers.length === 0) {
          repairerPeers = await Security_FindDirectChildrenWithAccess(
            repairerPersona.repairer_private,
            [Permissions.CasesEdit]
          );
        }
        peers = [...peers, ...repairerPeers];
      }

      if (
        warrantorPersona?.personaId &&
        warrantorPersona.warrantor_private?.principals?.[0]
      ) {
        let warrantorPeers: string[] = [];
        warrantorPeers = await Security_FindPeers(warrantorPersona.personaId, [
          Permissions.CasesEdit,
        ]);
        if (warrantorPeers.length === 0) {
          warrantorPeers = await Security_FindDirectChildrenWithAccess(
            warrantorPersona.warrantor_private,
            [Permissions.CasesEdit]
          );
        }
        peers = [...peers, ...warrantorPeers];
      }

      // Remove duplicates
      peers = [...new Set(peers)];

      // Fetch peer data based on collected IDs and filter results
      const filteredPeers = await filterPeers(peers);
      setRepairerPeer(
        filteredPeers.filter((item) => item !== null) as DTRepairer_Public[]
      );
      setRepairerPeerLoading(false);
    };

    // Trigger fetchPeers if repairerPersona exists
    if (repairerPersona?.personaId) {
      fetchPeers().catch(console.error);
    } else {
      setRepairerPeerLoading(false);
    }
  }, [
    repairerPersona?.repairer_private?.principals?.[0],
    repairerPersona?.personaId,
    warrantorPersona?.personaId,
  ]);

  return { repairerPeer, repairerPeerLoading };
}
