import {
  ShadowContainer,
  Heading,
  LoadingSpinner,
  IWarrantyLogoIcon,
} from '@rabbit/elements/shared-components';
import AuthForm from '../components/templates/AuthForm/AuthForm';
import { useState } from 'react';
import { useAppInfo } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo/logo-iwarranty-black.svg';

function LoginView() {
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const { t } = useTranslation();

  if (isLoading || t('CFG_COBRAND_NAME', '') === '')
    return <LoadingSpinner extraClasses={'mt-2'} size={'sm'} />;

  return (
    <div className="flex flex-grow flex-col justify-around">
      <ShadowContainer>
        <div className="px-14 pt-12 pb-8">
          <div className="flex flex-col items-start gap-1">
            <img
              src={appInfo.logo}
              alt={appInfo.name}
              className="flex max-h-[64px] justify-start object-contain"
            />
            <Heading kind={'h2'} className="text-gray-800">
              {t(
                'sage_auth_strapline',
                t('message.warrantyIntelligencePortal')
              )}
            </Heading>
          </div>
          <div className="relative my-8 flex h-8 justify-center">
            <hr className="absolute top-1/2 h-px w-[80%] w-full border border-t-0 border-gray-200" />
            <p className="font-caudex relative top-1/2 w-fit -translate-y-1/2 bg-white px-4 text-2xl font-bold text-gray-500">
              {t('message.logIn')}
            </p>
          </div>
          <AuthForm
            kind="Login"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
          {/* <div className="my-4 text-base font-nunito">
            No account? &nbsp;
            <NavLink
              to={ROUTE_NAME.SIGNUP}
              className="text-primary-600"
              reloadDocument
            >
              Create one
            </NavLink>
          </div> */}
        </div>
        <div className="flex items-center gap-1 rounded-b-[6px] border-t border-gray-200 bg-gray-50 px-14 py-4">
          <div className="font-caudex text-sm text-gray-600">
            {t('message.poweredBy')}
          </div>
          <div className="flex items-center">
            <img
              src={logo}
              alt="logo"
              className="max-h-[28px] object-contain"
            />
          </div>
        </div>
      </ShadowContainer>
    </div>
  );
}

export default LoginView;
