import {
  ForwardRefExoticComponent,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export interface TabsGenericProps {
  items: Array<TabItemProps>;
  query?: boolean;
  hasActivityUpdate?: boolean;
  hasChatUpdate?: boolean;
  hugTabs?: boolean;
  customTabs?: boolean;
  autoSelect?: boolean;
}

export interface TabItemProps {
  label: string;
  icon?: ForwardRefExoticComponent<SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  disabled?: boolean;
}

export function TabsGeneric(props: TabsGenericProps) {
  const {
    items,
    query = true,
    hasActivityUpdate,
    hasChatUpdate,
    hugTabs,
    customTabs,
    autoSelect = true,
  } = props;
  const navigate = useNavigate();

  const [activeTab, setActive] = useState<number>(autoSelect ? 0 : -1);
  const [paramValue, setParamValue] = useState<string>('');

  const QUERY_PARAM = 'tab';
  const searchParams = new URLSearchParams(window.location.search);

  const setActiveTab = (value: string) => {
    for (let i = 0; i < items.length; i++) {
      const label = String(items[i].label).toLocaleLowerCase();
      if (value.toLocaleLowerCase() === label) {
        setActive(i);
        if (query) {
          navigate({
            search: createSearchParams({ tab: label }).toString(),
          });
        }
      }
    }
  };

  useEffect(() => {
    const currentValue = searchParams.get(QUERY_PARAM) || '';
    if (currentValue !== paramValue) {
      setParamValue(currentValue);
      setActiveTab(currentValue);
    }
  }, [window.location.search, QUERY_PARAM, paramValue]);

  return (
    <div
      className={classNames(
        '',
        {
          'flex flex-col items-center': hugTabs || customTabs,
        },
        {
          'w-full': customTabs,
        }
      )}
    >
      {items && items.length > 1 && (
        <div
          className={`border-1 mb-5 flex justify-around overflow-hidden overflow-x-auto border text-gray-500 shadow-sm lg:inline-flex lg:justify-start ${
            !hugTabs && !customTabs ? 'w-full' : ''
          } ${customTabs ? 'w-full rounded-[28px]' : 'rounded-md'}`}
        >
          {items?.map((item, index) => (
            <div
              key={index}
              className={`lg:px-auto flex-grow cursor-pointer border-gray-200 py-4 text-center
                ${customTabs && activeTab !== index ? 'bg-white' : ''}
                ${
                  activeTab === index && !customTabs
                    ? 'bg-white'
                    : 'bg-gray-100'
                }
                ${index !== items.length - 1 ? 'border-r-[1px]' : ''}
                ${item.disabled ? 'cursor-auto opacity-50' : ''} ${
                customTabs && activeTab === index ? 'bg-primary-500' : ''
              }`}
              onClick={() => !item.disabled && setActiveTab(item.label)}
            >
              <div
                className={classNames(
                  'flex items-center justify-center gap-2 font-bold',
                  {
                    'px-16': hugTabs,
                  },
                  `${
                    customTabs && activeTab === index
                      ? 'text-white'
                      : 'text-gray-500'
                  } ${customTabs && 'text-lg'}`
                )}
              >
                {item.label}
                {item.label === 'Chat' && hasChatUpdate && (
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                )}
                {item.label === 'Activity' && hasActivityUpdate && (
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="w-full">
        {items && items[activeTab] && items[activeTab].children}
      </div>
    </div>
  );
}

export default TabsGeneric;
