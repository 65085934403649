import {
  DeciderOutputDecided,
  TableCover,
  WarrantyStatus,
} from '@rabbit/data/types';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  getCurrencyFormat,
  getWarrantyTerm,
  LabelledItem,
  LabelledItemList,
} from '@rabbit/elements/shared-components';
import {
  formatDate,
  renderWarrantyRemaining,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { OurDateTime } from '@rabbit/utils/ts';
import { RegistrationsWarrantyDetailsSectionVariationProps } from './RegistrationsWarrantyDetailsSection';
import { t } from 'i18next';

export function RegistrationsWarrantyDetailsSectionStoneWarranty({
  holding_proxy,
  title,
  warrantyInfo,
}: RegistrationsWarrantyDetailsSectionVariationProps) {
  const decidedOutput: DeciderOutputDecided | undefined =
    warrantyInfo?.decider?.decided;
  const appInfo = useAppInfo();

  const getTermExpired = (expiryDate: number) => {
    return expiryDate < OurDateTime.nowUTCTimestamp() ? 'Yes' : 'No';
  };

  const items = [
    {
      key: 'ID',
      label: t('Warranty ID'),
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase(),
    },
    {
      key: 'Service plan type',
      label: t('Service plan type'),
      value: warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-',
    },
    {
      key: 'Area',
      label: t('Area'),
      value:
        (holding_proxy.self_registration?.srvInfo?.productInfo as TableCover)
          ?.surfaceAreaValue + 'm²',
    },
    {
      key: 'Registration date',
      label: t('Registration date'),
      value: holding_proxy.register_time
        ? formatUnixTime(holding_proxy.register_time, 'dd/MM/yyy')
        : '-',
    },
    {
      key: 'Start date',
      label: t('Start date'),
      value: warrantyInfo?.startDate
        ? formatDate(new Date(warrantyInfo.startDate))
        : '-',
    },
    {
      key: 'Expiry date',
      label: t('Expiry date'),
      value:
        warrantyInfo?.status === WarrantyStatus.VOIDED
          ? 'Voided'
          : warrantyInfo?.endDate
          ? formatDate(new Date(warrantyInfo.endDate))
          : '-',
    },
    {
      key: 'Service plan term',
      label: t('Service plan term'),
      value: getWarrantyTerm(holding_proxy) || '-',
    },
    {
      key: 'Term remaining',
      label: t('Term remaining'),
      value: holding_proxy
        ? renderWarrantyRemaining(holding_proxy, t, false)
        : '-',
    },
    {
      key: 'Term expired',
      label: t('Term expired'),
      value: warrantyInfo?.endDate
        ? getTermExpired(warrantyInfo?.endDate)
        : '-',
    },
    {
      key: 'Extended service plan',
      label: t('Extended service plan'),
      value: 'Yes',
    },
    {
      key: 'Partner',
      label: t('Partner'),
      value: warrantyInfo?.retailerName || '-',
    },
    {
      key: 'Salesperson',
      label: t('Salesperson'),
      value: warrantyInfo?.salesRep || '-',
    },
    {
      key: 'Price',
      label: t('Price'),
      value: decidedOutput
        ? getCurrencyFormat(
            decidedOutput.warrantyPrice as number,
            appInfo.currency
          ) + ' + GST'
        : '-',
    },
  ];
  return (
    <>
      <CardWrapperWithHeader title={title}>
        <LabelledItemList items={items} />
      </CardWrapperWithHeader>
      {decidedOutput?.sealingPrice ? (
        <CardWrapperWithHeader title={t('Extra services')}>
          <div className="font-nunito grid grid-cols-5 gap-8">
            {decidedOutput?.sealingPrice && (
              <LabelledItem
                key="Sealing price"
                label={t('Sealing price')}
                value={
                  getCurrencyFormat(
                    decidedOutput.sealingPrice as number,
                    appInfo.currency
                  ) + ' + GST'
                }
              />
            )}
          </div>
        </CardWrapperWithHeader>
      ) : null}
    </>
  );
}
