import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { Dispatch, SetStateAction } from 'react';
import CreateNewConsumerDetails from '../forms/CreateNewConsumerDetails/CreateNewConsumerDetails';
import CreateNewCoverageOptions from '../forms/CreateNewCoverageOptions/CreateNewCoverageOptions';
import CreateNewRegistration from '../forms/CreateNewRegistration/CreateNewRegistration';
import { CreateNewRegistrationSummary } from '../forms/CreateNewRegistrationSummary/CreateNewRegistrationSummary';
import CreateNewSelectCar from '../forms/CreateNewSelectCar/CreateNewSelectCar';
import CreateNewSelectStone from '../forms/CreateNewSelectStone/CreateNewSelectStone';
import CreateNewStoneCoverageOptions from '../forms/CreateNewStoneCoverageOptions/CreateNewStoneCoverageOptions';
import { CreateNewRegistrationStoneSummary } from '../forms/CreateNewStoneRegistrationSummary/CreateNewStoneRegistrationSummary';
import NewRegistrationDetailsForm from '../forms/NewRegistrationDetailsForm/NewRegistrationDetailsForm';
import PWCreateNewCoverageOptions from './pinnacle-warranties/PWCreateNewCoverageOptions';
import PWCreateNewRegistration from './pinnacle-warranties/PWCreateNewRegistration';
import { PWCreateNewRegistrationSummary } from './pinnacle-warranties/PWCreateNewRegistrationSummary';
import PWNewRegistrationDetailsForm from './pinnacle-warranties/PWNewRegistrationDetailsForm';
import CreateNewSelectUser from '../forms/CreateNewSelectUser/CreateNewSelectUser';
import CreateNewConsumerForm from '../forms/CreateNewConsumerForm/CreateNewConsumerForm';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import NewCreateHoldingForm from '../forms/NewCreateHoldingForm/NewCreateHoldingForm';
import { useTranslation } from 'react-i18next';

interface RenderProps {
  currentStep: {
    step: number;
    data: any;
  };
  onChangeStep: (step_in: number, data: any) => void;
  handleClose: () => void;
  tenantType: TenantType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const RenderWarrantyIrelandFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType,
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && (
        <CreateNewSelectCar
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={(loading) => setIsLoading(loading)}
        />
      )}
      {currentStep.step === 1 && (
        <CreateNewRegistration
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 2 && (
        <NewRegistrationDetailsForm
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 3 && (
        <CreateNewCoverageOptions
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />
      )}
      {currentStep.step === 4 && (
        <CreateNewConsumerDetails
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          tenantType={tenantType}
        />
      )}
      {currentStep.step === 5 && (
        <CreateNewRegistrationSummary
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export const RenderNuCoverFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType,
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && (
        <CreateNewSelectStone
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={(loading) => setIsLoading(loading)}
        />
      )}
      {currentStep.step === 1 && (
        <CreateNewStoneCoverageOptions
          onChange={onChangeStep}
          data={currentStep.data}
          setIsLoading={setIsLoading}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 2 && (
        <CreateNewConsumerDetails
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          tenantType={tenantType}
        />
      )}
      {currentStep.step === 3 && (
        <CreateNewRegistrationStoneSummary
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export const RenderPinnacleWarrantiesFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType,
}: RenderProps) => {
  return (
    <>
      {currentStep.step === 0 && (
        <CreateNewSelectCar
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={(loading) => setIsLoading(loading)}
        />
      )}
      {currentStep.step === 1 && (
        <PWCreateNewRegistration
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 2 && (
        <PWNewRegistrationDetailsForm
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 3 && (
        <PWCreateNewCoverageOptions
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />
      )}
      {currentStep.step === 4 && (
        <CreateNewConsumerDetails
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          tenantType={tenantType}
        />
      )}
      {currentStep.step === 5 && (
        <PWCreateNewRegistrationSummary
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export const RenderGenericFlow = ({
  onChangeStep,
  currentStep,
  handleClose,
  setIsLoading,
  tenantType,
}: RenderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getStepByString = (step_label: string) => {
    const stepMapping: { [key: string]: number } = {
      'new-consumer': 1,
      'choose-product': 2,
      'add-product': 2,
    };
    return stepMapping[step_label];
  };

  const holdingCreated = (result: any) => {
    console.log('holdingCreated', result);

    toast.success(
      t('Registration created successfully. Redirecting to detail page...')
    );
    handleClose();
    setTimeout(() => {
      navigate(`${SAGE_ROUTE_NAME.REGISTRATIONS}/${result.holdingLink}`);
      handleClose();
    }, 6000);
  };

  return (
    <>
      {currentStep.step === 0 && (
        <CreateNewSelectUser
          onChange={(step_label: string, data: any = null) => {
            const step = getStepByString(step_label);
            if (step) {
              onChangeStep(step, data);
            }
          }}
          data={currentStep.data}
          handleClose={handleClose}
          setLoading={setIsLoading}
          kind="new-registration"
        />
      )}
      {currentStep.step === 1 && (
        <CreateNewConsumerForm
          onChange={(step_label: string, data: any = null) => {
            const step = getStepByString(step_label);
            if (step) {
              onChangeStep(step, data);
            }
          }}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 2 && (
        <NewCreateHoldingForm
          onChange={(step_label: string, data: any = null) => {
            const step = getStepByString(step_label);
            if (step) {
              onChangeStep(step, data);
            }
          }}
          data={currentStep.data}
          handleClose={handleClose}
          setLoading={setIsLoading}
          onHoldingCreated={holdingCreated}
        />
      )}
    </>
  );
};
