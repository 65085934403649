import { ComponentType, CSSProperties, Ref } from 'react';
import classNames from 'classnames';
import { PlusIcon } from '@heroicons/react/24/outline';

type ButtonIconProps = {
  label: string;
  onClick: () => any;
  type?: 'primary' | 'secondary';
  kind?:
    | 'bgGreen'
    | 'hoverBgRed'
    | 'bgLightGreen'
    | 'bgRed'
    | 'activeBgLightGreen'
    | 'bgWhite';
  iconLeft?: boolean;
  count?: number;
  Icon?: ComponentType<any>;
  disabled?: boolean;
  active?: boolean;
  ref?: Ref<any>;
  className?:string;
};

const dynamicStyles = {
  container:
    'inline-flex gap-2 px-4 py-2 items-center justify-center font-nunito rounded-lg text-sm font-medium grow cursor-pointer flex-shrink-0',
    bgDefault: 'bg-primary-900 text-white hover:bg-primary-800',
  bgRed: 'bg-red-600 min-h-full text-sm text-white hover:bg-red-500',
  hoverBgRed:
    'bg-white hover:bg-red-500 hover:text-white text-gray-700 shadow p-5 text-base',
  bgLightGreen: 'bg-primary-700 text-white hover:bg-primary-800',
  activeBgLightGreen:
    'bg-white hover:bg-primary-700 hover:text-white text-gray-700 shadow p-5 text-base',
  bgWhite:
      'bg-white hover:bg-primary-700 hover:text-white text-gray-700 text-base border border-primary-700',
  disabled: 'opacity-60 !cursor-default !pointer-events-none',
};

export function ButtonIcon({
  label,
  onClick,
  iconLeft = false,
  kind,
  count,
  Icon = PlusIcon,
  disabled = false,
  active,
  className
}: ButtonIconProps) {
  return (
    <div
      className={classNames(`${dynamicStyles.container}`, {
        [`${dynamicStyles.bgDefault}`]: !kind, //default color
        [`${dynamicStyles.hoverBgRed}`]: kind === 'hoverBgRed',
        [`${dynamicStyles.bgRed}`]: kind === 'bgRed',
        [`${dynamicStyles.bgLightGreen}`]:
          kind === 'bgLightGreen' || (kind === 'activeBgLightGreen' && active),
        [`${dynamicStyles.activeBgLightGreen}`]:
          kind === 'activeBgLightGreen' && !active,
          [`${dynamicStyles.bgWhite}`]: kind === 'bgWhite',
        [`${dynamicStyles.disabled}`]: disabled,
      },`${className}`)}
      onClick={() => !disabled && onClick()}
    >
      {iconLeft && <Icon className="h-3 w-3 flex-shrink-0" />}
      {label && (
        <button disabled={disabled} type="button">
          {label}
        </button>
      )}
      {!iconLeft && <Icon className="h-4 w-4 flex-shrink-0" />}
      {count ? (
        <span className="text-primary-900 flex h-4 w-4 items-center justify-center rounded-full bg-white text-sm">
          {count}
        </span>
      ) : null}
    </div>
  );
}

export default ButtonIcon;
