import i18n, { BackendModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { CFC_GetLexicon, GenericLexicon } from '@rabbit/mixmaster/core';
import { CloudFunctionSetRider } from '@rabbit/firebase/doctype';
import { RecipeToLanguageString } from './recipe';
import { RabbitEnvironments } from '@rabbit/data/types';

//@ts-ignore
const FIREBASE_MODE = import.meta.env.VITE_FIREBASE_MODE;

let booted = false;
let bootStarted = false;
let lexiconBooted = false;
let lexiconBootStarted = false;

console.debug('MixMaster: First Chance @ ', new Date().getTime());

const translationFiles: { [key: string]: () => Promise<any> } = {
  en: () => import('../../locales/en/translation.json'),
  nl: () => import('../../locales/nl/translation.json'),
  th: () => import('../../locales/th/translation.json'),
};

async function getTranslationFile(language: string): Promise<any> {
  if (translationFiles[language]) {
    return translationFiles[language]();
  } else {
    console.warn(
      `No local translations found for language: ${language}, falling back to English.`
    );
    return translationFiles['en']();
  }
}

export async function BootLexicon(bootstrap: GenericLexicon) {
  if (lexiconBootStarted) return;
  lexiconBootStarted = false;
  const recipeString = RecipeToLanguageString();
  CloudFunctionSetRider('lex', recipeString);
  const veilsConfig = recipeString.split('@').join('_');
  if (veilsConfig === 'bootstrap') return bootstrap;
  const configLoaded = await CFC_GetLexicon.call({
    id: `${veilsConfig}`,
  });
  const activeTenantInLS = localStorage.getItem('activeTenant');
  if (configLoaded.ok) {
    CloudFunctionSetRider('lex', veilsConfig);

    // store the tenantLink in local storage so it can be used to load up the app config
    // and also dispatch an event to let the appConfigContext know the tenant has changed
    if (activeTenantInLS !== configLoaded.data.body.tenantLink) {
      localStorage.setItem('activeTenant', configLoaded.data.body.tenantLink);
      window.dispatchEvent(new Event('activeTenantChange'));
    }
    lexiconBooted = true;
    return configLoaded?.data.body;
  } else {
    return configLoaded.error;
  }
}

export async function BootI18N(bootstrap: GenericLexicon, configData: any) {
  if (bootStarted) return;

  bootStarted = true;

  console.debug('MixMaster: BootI18N @ ', new Date().getTime(), bootstrap);

  async function GetLanguage(inlanguage: string, namespace: string) {
    // Dynamically import local translations based on the language obtained from results
    let localLanguage = inlanguage;
    let localTranslations = {};
    try {
      localTranslations = await getTranslationFile(localLanguage);
    } catch (error) {
      console.error(
        `Error importing local translations for ${localLanguage}:`,
        error
      );
    }
    // Merge local and remote translations
    return {
      ...localTranslations,
      ...configData,
      ...{ localLanguage: localLanguage },
    };
  }

  const loaderBackend: BackendModule = {
    type: 'backend',
    init: function (services, backendOptions, i18nextOptions) {
      /* use services and options */
      console.debug('MixMaster: BACKEND INIT');
    },
    read: async function (language, namespace, callback) {
      try {
        const results = await GetLanguage(language, namespace);
        callback(null, results);
      } catch (e) {
        /* if method fails/returns an error, call this: */
        console.error('MixMaster: error in loading', language, namespace, e);
        callback('Loading error', null);
        throw e;
      }
    },

    // only used in backends acting as cache layer
    save: function (language, namespace, data) {
      // store the translations
      // console.log('BACKEND SAVE', language, namespace, data);
    },

    create: function (languages, namespace, key, fallbackValue) {
      /* save the missing translation */
      console.debug(
        'MixMaster: MISSING LANGUAGE ENTRY\n',
        `  "${key}": "${fallbackValue}"`
      );

      // a quick and dirty way to store missing translations in local storage, just as a proof of concept
      // this should be replaced with a proper solution eventually
      if (FIREBASE_MODE === RabbitEnvironments.EMULATOR) {
        const missing = localStorage.getItem('missingTranslations');
        let missingTranslations: { [key: string]: string } = {};
        if (missing) {
          missingTranslations = JSON.parse(missing);
          missingTranslations[key] = fallbackValue;
        } else {
          missingTranslations = { [key]: fallbackValue };
        }
        localStorage.setItem(
          'missingTranslations',
          JSON.stringify(missingTranslations)
        );
      }
    },
  };

  const InitialInitialisation = await i18n.use(initReactI18next);

  const FurtherInitialisation = async () => {
    await InitialInitialisation
      // load translation using our custom backend loader
      .use(loaderBackend)
      // pass the i18n instance to react-i18next.
      // // init i18next
      // // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
          useSuspense: false,
        },
        saveMissing: true,
        saveMissingTo: 'fallback',
      });
    // Sensing goes here
    booted = true;

    const language = configData.CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE;

    await ChangeLanguage(language);
  };

  void FurtherInitialisation();

  await InitialInitialisation;
}

export function HasLanguageBooted() {
  return booted;
}
export function HasLexiconBooted() {
  return lexiconBooted;
}

export async function ChangeLanguage(language: string) {
  await i18n.changeLanguage(language); // Split and join since i18n has its own ideas about underscore
}
