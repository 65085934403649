import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                 Shop schema                                */
/* -------------------------------------------------------------------------- */

export interface DTShopifyShopConfig extends NoSqlDoc {
  /**
   * Whether to skip importing products from this shop
   */
  skipIntegration: boolean;
  /**
   * The flag to check against in the product tags to determine if it should be skipped
   */
  skipIntegrationFlag?: string;
  /**
   * The Shopify shop URI, which serves as the unique identifier for the shop. E.g bananamart.myshopify.com
   */
  shopLink: string;
  /**
   * The Rabbit tenant link
   */
  tenantLink: string;
}

export const FBD_ShopifyShopConfig = MakeFBDocType<DTShopifyShopConfig>({
  name: 'Shopify_Shop_Config',
  collection: 'shopify_shop_config',
  empty: () => {
    const result: DTShopifyShopConfig = {
      ...Empty_NoSqlDoc(),
      skipIntegration: false,
      shopLink: '',
      tenantLink: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Shopify_Shop key should be the shop URL'),
});
