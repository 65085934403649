import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { TenantLink } from './tenant';
import { PersonaLink } from '../persona';
/* -------------------------------------------------------------------------- */
/*                                   Causes                                   */
/* -------------------------------------------------------------------------- */

/** A type representing a cause associated with a fault */
export interface DTCause extends NoSqlDoc {
  /** The name of the cause */
  title: string;

  /** Enable/disable flag */
  enabled: boolean,

  /** A description of the cause */
  description?: string;

  /** A link to the tenant that owns this cause */
  tenantLink?: TenantLink;

  /** A list of principals that have access to this cause */
  principals?: PersonaLink[];
}

export const FBD_Cause = MakeFBDocType<DTCause>({
  name: 'Cause',
  collection: 'cause',
  empty: () => {
    const result: DTCause = {
      ...Empty_NoSqlDoc(),
      title: '',
      enabled: true,
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

export type CauseLink = string;