import { useContext } from 'react';
import {
  formatUnixTime,
  LabelledItemShape,
  parseId,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';
import { stripTags } from '@rabbit/sage/utils/helpers';

export interface ClaimDetailsSectionType1Props {
  renderFns: {
    renderCaseFiles: () => JSX.Element;
    renderConsumerIssueDesc: () => JSX.Element;
    renderClaimDetailItemsLine: (
      claimDetailItems: LabelledItemShape[],
      keys: string[],
      extraClasses?: string
    ) => JSX.Element;
  };
  faultLabels: string[];
}

export function ClaimDetailsSectionType1(props: ClaimDetailsSectionType1Props) {
  const { faultLabels } = props;
  const {
    renderCaseFiles,
    renderConsumerIssueDesc,
    renderClaimDetailItemsLine,
  } = props.renderFns;
  const { caseFacts, holdingData, holdingProxyData, caseId, caseTimeCreated } =
    useContext(CaseflowContext) || {};

  const { t } = useTranslation();
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holdingProxyData?.warranties
  );

  const isWithinWarranty = () => {
    if (!holdingProxyData) return '-';

    if (!latestWarranty || !latestWarranty.endDate) return '-';

    return OurDateTime.nowUTCTimestamp() < latestWarranty?.endDate
      ? 'Yes'
      : 'No';
  };

  /* --------------------------- Claim detail items --------------------------- */
  const claimDetailItems: LabelledItemShape[] = [
    {
      key: 'claim_id',
      label: t('general.claimId'),
      value: caseId ? parseId(caseId) : '-',
    },
    {
      key: 'date_of_claim',
      label: t('general.dateOfClaim'),
      value: caseTimeCreated
        ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
        : '-',
    },
    {
      key: 'purchase_date',
      label: t('general.purchaseDate'),
      value:
        holdingData?.purchase_time && holdingData?.purchase_time > 0
          ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'warranty_term',
      label: t('general.warrantyTerm'),
      value: holdingData?.warranty_term?.amount
        ? `${holdingData?.warranty_term?.amount} ${holdingData?.warranty_term?.division}`
        : '-',
    },
    {
      key: 'warranty_expiry',
      label: t('general.warrantyExpiry'),
      value:
        latestWarranty?.endDate && latestWarranty?.endDate > 0
          ? formatUnixTime(latestWarranty?.endDate, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'within_warranty_period',
      label: t('general.withinWarrantyPeriod'),
      value: isWithinWarranty(),
    },
    {
      key: 'customer_issue_type',
      label: t('general.customerIssueType'),
      value: caseFacts?.consumer_issue_type ?? '-',
    },
    {
      key: 'customer_issue_description',
      label: t('general.customerIssueDescription'),
      value: stripTags(caseFacts?.consumer_issue_description ?? '') ?? '-',
    },
    {
      key: 'proof_of_purchase',
      label: t('general.proofOfPurchaseSupportingMaterials'),
      value: renderCaseFiles(),
    },
    {
      key: 'serial_number',
      label: t('general.serialNumber'),
      value: caseFacts?.consumer_holding_serial_number
        ? caseFacts.consumer_holding_serial_number
        : caseFacts?.serial_number
        ? caseFacts.serial_number
        : '-',
    },
  ];

  /* -------------------------------- Main TSX -------------------------------- */
  return (
    <div>
      {renderClaimDetailItemsLine(claimDetailItems, [
        'claim_id',
        'date_of_claim',
        'purchase_date',
        'warranty_term',
        'warranty_expiry',
      ])}
      {renderClaimDetailItemsLine(claimDetailItems, [
        'customer_issue_type',
        'customer_issue_description',
        'within_warranty_period',
        'proof_of_purchase',
        'serial_number',
      ])}
    </div>
  );
}

export default ClaimDetailsSectionType1;

/* -------------------------------------------------------------------------- */
/*                                 Unused code                                */
/* -------------------------------------------------------------------------- */

// Afaik this isn't being used anywhere yet.
// Keeping it here because it related to a planned feature that we haven't implemented yet.
// And thus can save us some time in the future. - DC
//TODO: extract the modal into a separate component (when needed)

// const [partsReplacedModal, setPartsReplacedModal] = useState(false);

// const modalSettingsPartsReplaced: ModalSettingsShape = {
//   title: t('Parts replaced'),
//   handleClose: () => setPartsReplacedModal(false),
// };

// const getTotalValueOfParts = () => {
//   let subtotalSum = 0;

//   caseFacts?.parts_to_replace?.forEach((part) => {
//     // Todo: review if Number necessary
//     const subtotal = multiply(
//       Number(part?.parts_quantity),
//       Number(part?.parts_cost?.amount)
//     );

//     subtotalSum += subtotal;
//   });

//   return subtotalSum;
// };

/* ------- TODO: check designs to show parts and confirm this -------- */

//  {partsReplacedModal && (
//   <Modal
//     kind="generic"
//     settings={modalSettingsPartsReplaced}
//     className="m-auto w-[724px] rounded-md border bg-white p-5"
//   >
//     <div className="flex flex-col justify-center">
//       <div className="font-nunito mt-3 grid grid-cols-5 bg-gray-100 p-5">
//         <p>Part name</p>
//         <p>Model id</p>
//         <p>Quantity</p>
//         <p>Cost</p>
//         <p>Subtotal</p>
//       </div>
//       {caseFacts?.parts_to_replace?.map(
//         (part: {
//           id: string;
//           part_name: string;
//           model_id: string;
//           parts_quantity: string;
//           parts_cost: { amount: string; currency: string };
//         }) => {
//           return (
//             <div
//               key={part.id}
//               className="font-nunito grid grid-cols-5 p-5"
//             >
//               <p>{part.part_name}</p>
//               <p>{part.model_id}</p>
//               <p>{part.parts_quantity}</p>
//               <p>
//                 {part.parts_cost?.amount} {part.parts_cost?.currency}
//               </p>
//               <p>
//                 {/* Todo: review if number necessary */}
//                 {multiply(
//                   Number(part.parts_quantity),
//                   Number(part.parts_cost?.amount)
//                 )}
//               </p>
//             </div>
//           );
//         }
//       )}
//     </div>
//     <div className="font-nunito mt-5 flex justify-start font-medium">
//       Total spent: {getTotalValueOfParts()}
//     </div>
//   </Modal>
// )}
