import {
  CardWrapperWithHeader,
  formatUnixTime,
  getClaimLimit,
  getCurrencyFormat,
  getIncreasedLabourRate,
  getWarrantyTerm,
  isWarrantyExpired,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { WarrantyStatus } from '@rabbit/data/types';
import {
  formatDate,
  renderWarrantyRemaining,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import { RegistrationsWarrantyDetailsSectionVariationProps } from './RegistrationsWarrantyDetailsSection';

export default function RegistrationsWarrantyDetailsSectionCarWarranty({
  holding_proxy,
  title,
  warrantyInfo,
}: RegistrationsWarrantyDetailsSectionVariationProps) {
  const { t } = useTranslation();
  const appInfo = useAppInfo();

  const labourRateValue = getIncreasedLabourRate(
    warrantyInfo,
    appInfo.currency
  );

  const showVAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_VAT') === 'true';
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  const items: LabelledItemShape[] = [
    {
      key: 'ID',
      label: t('Warranty ID'),
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase(),
    },
    {
      key: 'Warranty plan',
      label: t('Warranty plan'),
      value: warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-',
    },
    {
      key: 'Registration date',
      label: t('Registration date'),
      value:
        warrantyInfo && warrantyInfo?.registeredTime > 0
          ? formatUnixTime(warrantyInfo.registeredTime, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'Warranty term',
      label: t('Warranty term'),
      value: getWarrantyTerm(holding_proxy) || '-',
    },
    {
      key: 'Start date',
      label: t('Start date'),
      value: warrantyInfo?.startDate
        ? formatDate(new Date(warrantyInfo.startDate))
        : '-',
    },
    {
      key: 'Expiry date',
      label: t('Expiry date'),
      value:
        warrantyInfo?.status === WarrantyStatus.VOIDED
          ? 'Voided'
          : warrantyInfo?.endDate
          ? formatDate(new Date(warrantyInfo.endDate))
          : '-',
    },
    {
      key: 'Warranty remaining',
      label: t('Warranty remaining'),
      value: holding_proxy
        ? renderWarrantyRemaining(holding_proxy, t, false)
        : '-',
    },
    {
      key: 'Warranty expired',
      label: t('Warranty expired'),
      value: warrantyInfo?.endDate
        ? isWarrantyExpired(warrantyInfo?.endDate)
        : '-',
    },
    {
      key: 'Claim limit',
      label: t('Claim limit'),
      value: warrantyInfo
        ? getClaimLimit(
            warrantyInfo,
            appInfo.currency,
            CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
          )
        : '-',
    },
    {
      key: 'Extended warranty',
      label: t('Extended warranty'),
      value: 'Yes',
    },
  ];

  // TODO: Sub this condition with config
  if (
    t('tenantLink') !== 'WARRANTYIRELAND' &&
    Array.isArray(labourRateValue) &&
    labourRateValue.length > 0
  ) {
    items.push({
      key: 'Labour rate',
      label: t('Labour rate'),
      value: labourRateValue[0],
    });
  }

  items.push(
    {
      key: 'Partner',
      label: t('Partner'),
      value: warrantyInfo?.retailerName || '-',
    },
    {
      key: 'Salesperson',
      label: t('Salesperson'),
      value: warrantyInfo?.salesRep || '-',
    },
    {
      key: 'Price',
      label: t('Price'),
      value: warrantyInfo?.price?.amount
        ? getCurrencyFormat(
            warrantyInfo?.price?.amount as number,
            warrantyInfo?.price?.currency
          ) +
          (showVAT
            ? ` (${getCurrencyFormat(
                warrantyInfo?.price?.amount * 1.2,
                warrantyInfo?.price?.currency
              )} incl. VAT)`
            : '')
        : '-',
    }
  );

  return (
    <CardWrapperWithHeader title={title}>
      <LabelledItemList items={items} />
    </CardWrapperWithHeader>
  );
}
