import React, { useCallback, useMemo, useRef, useState } from 'react';
// import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReact, } from "ag-grid-react";
// import { ColDef } from "@ag-grid-community/core";
// import { ClientSideRowModelModule, ModuleRegistry } from 'ag-grid-community'; // Import AG Grid types
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry, GetDetailRowDataParams, SizeColumnsToFitGridStrategy } from "@ag-grid-community/core";
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { ColDef } from 'ag-grid-enterprise';
import DetailCellRenderer from '../templates/ProductsTabElements/CellRender/DetailCellRenderer';
import { useTranslation } from 'react-i18next';
// import { GetDetailRowDataParams } from 'ag-grid-enterprise';


// Register AG Grid modules (optional: add other modules as needed)
ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule]);

interface CustomComponents {
  [componentName: string]: React.FC<any> | (() => JSX.Element);
}
// Define the type for the row data
interface AgGridTableProps {
  columnDefs: ColDef[]; // Column definitions
  rowData: any[]; // Row data
  pagination?: boolean; // Whether to enable pagination
  sideBar?: boolean; // Whether to enable the sidebar
  domLayout?: any; // AG Grid layout type, for example, 'autoHeight'
  style?: React.CSSProperties; // Optional custom styles
  paginationPageSizeSelector?: number[];
  components?: CustomComponents;
}

const AgGridTable: React.FC<AgGridTableProps> = ({
  columnDefs,
  rowData,
  pagination = true,
  sideBar = true,
  domLayout = 'autoHeight',
  style = { width: '100%' },
  components,
  paginationPageSizeSelector = [10, 25, 75, 100],
}) => {
  const gridRef = useRef<any>(null);
  const rowHeight = 80;
  const { t } = useTranslation();
  const [dynamicHeight,setDynamicHeight]=useState<number>(400)

  const getRowHeight = useCallback((params: any) => {
    return params.data.rowHeight;
  }, []);

  const autoSizeStrategy = useMemo<SizeColumnsToFitGridStrategy>(
    () => ({
      type: "fitGridWidth",
    }),
    []
  );

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  const onPaginationChanged = useCallback((params:any) => {
    if (gridRef.current) {
      const currentPageSize = gridRef.current.api.paginationGetPageSize();
      setDynamicHeight(currentPageSize*rowHeight)
    }
  }, []);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  const sideBarDef = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: t('general.filters'),
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        },
        {
          id: "columns",
          labelDefault: t('general.columns'),
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          pivoteMode: false,
        },
      ],
    };
  }, []);

  const hideWatermark = useCallback(() => {
    const watermark = document.querySelector('.ag-watermark') as HTMLElement;
    if (watermark) {
      watermark.style.display = 'none !important';
      watermark.innerText = "NONE"
    }
  }, []);

  const onGridReady = useCallback(() => {
    hideWatermark();
  }, [hideWatermark]);

  return (
    <div className='bg-[#F3F4F6] rounded-lg'>
      <div className="ag-theme-quartz" style={{...style,height:dynamicHeight}}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={rowData}
          pagination={pagination}
          domLayout={domLayout}
          sideBar={sideBarDef}
          getRowHeight={getRowHeight}
          rowHeight={rowHeight}
          paginationPageSize={10}
          components={components}
          masterDetail={true}
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight
          autoSizeStrategy={autoSizeStrategy}
          paginationPageSizeSelector={paginationPageSizeSelector}
          defaultColDef={defaultColDef}
          onPaginationChanged={onPaginationChanged}
        />
      </div>
    </div>
  );
};

export default AgGridTable;
