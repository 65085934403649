import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ButtonIcon, Modal } from '@rabbit/elements/shared-components';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalAddEditItem, {
  ModalAddEditItemKind,
  ModalAddEditItemMode,
} from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { useApi } from '@rabbit/utils/api';

export default function CauseTab() {
  const { t } = useTranslation();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [modalMode, setModalMode] = useState<ModalAddEditItemMode>(
    ModalAddEditItemMode.new
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { subscribe } = useApi();
  const { data: causeList, isLoading } = subscribe(
    'Cause',
    { enabled: true },
    { field: 'description', direction: 'desc' }
  );

  interface CauseListShape {
    docid: string;
    id: string;
    value: string;
    label: string;
    name: string;
    description: string;
  }

  const causeListOptions: CauseListShape[] =
    causeList?.map((cause) => ({
      docid: cause.docid,
      id: cause.docid,
      value: cause.docid,
      label: cause.title,
      name: cause.title,
      title: cause.title,
      description: cause.description || '-',
    })) || [];

  const handleEditModal = (itemDetails?: any) => {
    setItemDetails(itemDetails || null);
    setModalMode(
      itemDetails ? ModalAddEditItemMode.edit : ModalAddEditItemMode.new
    );
    setEditModalOpen(true);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t('message.confirmDeleteFault'),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const CauseTable = useMemo(
    () =>
      CreateTable<CauseListShape>(causeListOptions, [
        {
          header: t('general.name'),
          value: (cause) => cause.label,
        },
        {
          header: t('general.description'),
          value: (cause) => cause.description,
        },
        {
          header: '',
          accessorKey: 'actions',
          value: (cause) => (
            <div className="text-center">
              <ButtonIcon
                label={t('general.editCause')}
                kind={'bgLightGreen'}
                iconLeft={true}
                Icon={PencilSquareIcon}
                onClick={() => handleEditModal(cause)}
              />
            </div>
          ),
          size: 15,
          maxSize: 50,
        },
      ]),
    [causeList, isLoading]
  );

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={t('general.addCause')}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <CauseTable
        initialState={{
          columnPinning: { right: ['actions'] },
          showGlobalFilter: true,
          isLoading,
        }}
        muiSearchTextFieldProps={{
          placeholder: t('general.searchNameOrDescription'),
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind={ModalAddEditItemKind.causes}
            title={
              modalMode === ModalAddEditItemMode.edit
                ? t('general.editCause')
                : t('general.newCause')
            }
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </>
  );
}
