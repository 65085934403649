import {
  FBD_CaseFlowActorPrivate,
  FBD_CaseFlowCase,
  FBD_CaseFlowPrivateProxy,
  FBD_Consumer_Private,
  FBD_Holding_Public,
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  FBD_Identity,
  FBD_Identity_Private,
  FBD_Manufacturer_Private,
  FBD_Manufacturer_Public,
  FBD_Repairer_Private,
  FBD_Repairer_Public,
  FBD_Vendable,
  FBD_Vendable_Private,
  FBD_HoldingProxy,
  FBD_Fault,
  FBD_Fault_Category,
  FBD_Business_User_Invite,
  FBD_Consumer_Public,
  FBD_ShopifyVendable,
  FBD_ShopifyShop,
  FBD_ShopifyCustomer,
  FBD_ShopifyOrder,
  FBD_Consumer_Issue_Type,
  FBD_ShopifyVendable_Export,
  FBD_Warrantor_Private,
  FBD_Warrantor_Public,
  FBD_Warranty_Template,
  FBD_Warranty,
  FBD_Tenant_Private,
  FBD_Tenant_Public,
  FBD_Retailer_Private,
  FBD_Retailer_Public,
  FBD_WarrantyDealer_Private,
  FBD_WarrantyDealer_Public,
  FBD_Warranty_Offer,
  FBD_Vendable_History_Private,
  FBD_Vendable_History_Public,
  FBD_Cause,
} from '@rabbit/data/types';
import { MakeDRDocType } from '@rabbit/firebase/react';

/* --------------------------- Main iWarranty app --------------------------- */

// Holding
export const DR_Holding_Public = MakeDRDocType(FBD_Holding_Public);
export const DR_Holding_Private = MakeDRDocType(FBD_Holding_Private);
export const DR_Holding_Manufacturer = MakeDRDocType(FBD_Holding_Manufacturer);
export const DR_Holding_Proxy = MakeDRDocType(FBD_HoldingProxy);
// Vendable
export const DR_Vendable = MakeDRDocType(FBD_Vendable);
export const DR_Vendable_Private = MakeDRDocType(FBD_Vendable_Private);
export const DR_Vendable_History_Public = MakeDRDocType(
  FBD_Vendable_History_Public
);
export const DR_Vendable_History_Private = MakeDRDocType(
  FBD_Vendable_History_Private
);
// Identity
export const DR_Identity = MakeDRDocType(FBD_Identity);
export const DR_Identity_Private = MakeDRDocType(FBD_Identity_Private);
// Personas
export const DR_Consumer_Private = MakeDRDocType(FBD_Consumer_Private);
export const DR_Consumer_Public = MakeDRDocType(FBD_Consumer_Public);
export const DR_Repairer_Private = MakeDRDocType(FBD_Repairer_Private);
export const DR_Repairer_Public = MakeDRDocType(FBD_Repairer_Public);
export const DR_Retailer_Private = MakeDRDocType(FBD_Retailer_Private);
export const DR_Retailer_Public = MakeDRDocType(FBD_Retailer_Public);
export const DR_Manufacturer_Private = MakeDRDocType(FBD_Manufacturer_Private);
export const DR_Manufacturer_Public = MakeDRDocType(FBD_Manufacturer_Public);
export const DR_Warrantor_Private = MakeDRDocType(FBD_Warrantor_Private);
export const DR_Warrantor_Public = MakeDRDocType(FBD_Warrantor_Public);
export const DR_WarrantyDealer_Private = MakeDRDocType(
  FBD_WarrantyDealer_Private
);
export const DR_WarrantyDealer_Public = MakeDRDocType(
  FBD_WarrantyDealer_Public
);

// CaseFlow
export const DR_CaseFlowCase = MakeDRDocType(FBD_CaseFlowCase);
export const DR_CaseFlowActorPrivate = MakeDRDocType(FBD_CaseFlowActorPrivate);
export const DR_CaseFlowPrivateProxy = MakeDRDocType(FBD_CaseFlowPrivateProxy);
// Faults
export const DR_Fault = MakeDRDocType(FBD_Fault);
export const DR_Fault_Category = MakeDRDocType(FBD_Fault_Category);
export const DR_Consumer_Issue_Type = MakeDRDocType(FBD_Consumer_Issue_Type);
// User Invites
export const DR_Business_User_Invite = MakeDRDocType(FBD_Business_User_Invite);
// Warranties
export const DR_Warranty_Template = MakeDRDocType(FBD_Warranty_Template);
export const DR_Warranty_Offer = MakeDRDocType(FBD_Warranty_Offer);
export const DR_Warranty = MakeDRDocType(FBD_Warranty);
// Tenants
export const DR_Tenant_Private = MakeDRDocType(FBD_Tenant_Private);
export const DR_Tenant_Public = MakeDRDocType(FBD_Tenant_Public);
// Causes
export const DR_Cause = MakeDRDocType(FBD_Cause);

/* ------------------------------- Shoplifter ------------------------------- */
export const DR_ShopifyShop = MakeDRDocType(FBD_ShopifyShop);
export const DR_ShopifyVendable = MakeDRDocType(FBD_ShopifyVendable);
export const DR_ShopifyVendable_Export = MakeDRDocType(
  FBD_ShopifyVendable_Export
);
export const DR_ShopifyOrder = MakeDRDocType(FBD_ShopifyOrder);
export const DR_ShopifyCustomer = MakeDRDocType(FBD_ShopifyCustomer);
