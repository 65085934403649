import {
  CaseFlowCaseEditingID,
  CaseFlow_Utils_AlterCaseFacts,
  ReactCaseFlowCase,
  useCaseFlowCase,
  useConsumerProfile,
  useGetConsumerHoldings,
} from '@rabbit/bizproc/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  LoadingSpinner,
  ModalProps,
  TimelineUpdateShape,
} from '@rabbit/elements/shared-components';
import {
  ConsumerHoldingSummaryShape,
  getRootPersonaFromLexicon,
} from '@rabbit/bizproc/client';
import {
  DTHoldingProxy,
  Gazette,
  GazetteEmailParams,
  GEChat,
  OneEachOptionalPersonaLink,
  PersonaIdTypeSplitter,
  PersonaLink,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
} from '@rabbit/data/types';
import { FBD_CaseFlowCase, ActorContext } from '@rabbit/data/types';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';
import { CaseFactsShape } from '@rabbit/bizproc/core';
import { useGetMySagePersonas } from '@rabbit/data/portal';

// TODO: Add types for all the data
export interface CaseflowInterface {
  holdingData: ConsumerHoldingSummaryShape | null;
  holdingProxyData: DTHoldingProxy | null;
  getHoldingData: () => Promise<ConsumerHoldingSummaryShape | null>;
  operatingPersona: PersonaLink;
  consumerPersonaData: any;
  //consumerIdentityData: any;
  caseFacts: Partial<CaseFactsShape> | undefined;
  caseState: any;
  caseActionsStation: any;
  caseActionsAll: any;
  caseActionsGlobal: any;
  chatLog: GEChat[] | undefined;
  caseActors: any;
  caseUpdates: Gazette | undefined;
  caseAlterability: string | undefined;
  modalSettings: ModalProps;
  setModalSettings: React.Dispatch<React.SetStateAction<ModalProps>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  executeAction: (
    action: string,
    params?: { [key: string]: any }
  ) => Promise<void>;
  alterCaseFacts: (facts: { [key: string]: any }) => Promise<void>;
  alterCasePublicEmail: (emailParams: GazetteEmailParams) => Promise<void>;
  sendChatMessage: (
    actor: keyof OneEachOptionalPersonaLink,
    message: string
  ) => void;
  caseFlowCase: ReactCaseFlowCase | null;
  caseId: string;
  operatingPersonaSingleKey: PersonaTypeSingleLetter;
  moveSpotlight: (target: PersonaTypeSingleLetter | 'NONE') => Promise<void>;
  getMyChatLastSeen: () => number | undefined;
  updateChatLastSeen: (actor: keyof OneEachOptionalPersonaLink) => void;
  caseTimeCreated: number | undefined;
  caseTimeUpdated: number | undefined;
  caseExists: boolean | null;
  caseStateHistory: string[] | undefined;
  caseSpotlight: any | undefined; // todo
  caseActorContext: ActorContext | null;
}

const CaseflowContext = createContext<CaseflowInterface | null>(null);

type CaseflowProviderWrapperProps = {
  children: React.ReactNode;
  caseId: string;
  operatingPersona: PersonaLink;
  isAdmin?: boolean;
};

// TODO: Check which functions and data to memoize
//   const data = useMemo(
//     () => ({
//       holdingData,
//       consumerData,
//     }),
//     [holdingData, consumerData]
//   );

const CaseflowProviderWrapper = ({
  children,
  caseId,
  operatingPersona,
  isAdmin,
}: CaseflowProviderWrapperProps) => {
  const { config } = useContext(ConfigContext);
  const tenantLink = t('tenantLink');
  const rootOperatingPersona = t('CFG_OPERATING_PERSONA');

  // TODO:  CHANGE HOW WE GET OPERATING / PRINCIPAL PERSONA HERE
  const rootTenantRepairer = getRootPersonaFromLexicon(
    t(PrincipalsFieldName),
    PersonaTypeSingleLetter.Repairer
  );

  // @TEST_REMOVE_LATER, as the principals field name wasn't updated locally even after running mixmaster...
  const rootTenantWarrantor =
    PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink;
  // const rootTenantWarrantor = getRootPersonaFromLexicon(
  //   t(PrincipalsFieldName),
  //   PersonaTypeSingleLetter.Warrantor
  // );

  // The first letter of the operating persona is the persona type
  const operatingPersonaSingleKey: PersonaTypeSingleLetter =
    operatingPersona.split(PersonaIdTypeSplitter)[0] as PersonaTypeSingleLetter;

  // Start up the caseflow case object
  // This context handles distibuting case data and actions to all components used in displaying a single case view on Sage.
  const caseEditingID: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: caseId,
    operating_persona: operatingPersona,
    // todo: this needs a lot of further work to distinguish between internal and external repairers in the future
    // but is good enough for now - DC
    principal_persona: config.CLAIMS.INTERNAL_REPAIRERS_ENABLED
      ? rootTenantRepairer
      : operatingPersona,
    isNewCase: false,
    isAdmin: isAdmin,
  };
  const { caseFlowCase } = useCaseFlowCase(caseEditingID);

  /* --------------------------------- STATES --------------------------------- */
  //todo remove holdingData and keep proxy data only when all components have been updated to use holdingProxyData
  const [holdingData, setHoldingData] =
    useState<ConsumerHoldingSummaryShape | null>(null);
  const [holdingProxyData, setHoldingProxyData] =
    useState<DTHoldingProxy | null>(null);
  const [modalSettings, setModalSettings] = useState<ModalProps>(
    {} as ModalProps
  );
  const [showModal, setShowModal] = useState(false); // todo unnecessary?, can toggle show or not with just modalSettings state
  const [caseExists, setCaseExists] = useState<boolean | null>(null);

  // Get facts, actorContext and relevant ids so we can initialize the context without anything breaking
  const caseFacts = caseFlowCase?.GetAllFacts();

  const caseActorContext = caseFlowCase?.GetActorContext();

  const consumerId = caseFacts?.consumer_persona_id;

  const consumerHoldingId = caseFacts?.consumer_holding;

  const holdingVendableId = caseFacts?.holding_vendable_id;

  const getHoldingData = async () => {

    const holdingProxy = await getSingleHoldingProxy(
      consumerHoldingId + '_' + holdingVendableId
    );
    if (holdingProxy instanceof Error) return null;
    else setHoldingProxyData(holdingProxy);
    // TODO: Remove this once everything has been changed to use holdingProxy above
    const holding = await getSingleHolding(consumerHoldingId);
    if (holding instanceof Error) return null;
    else setHoldingData(holding);
    return holding;
  };

  useEffect(() => {

    if (consumerHoldingId) {
      (async () => {
        await getHoldingData();
      })().catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerHoldingId]);

  useEffect(() => {
    (async () => {
      const theCase = await FBD_CaseFlowCase.get(caseId);
      if (!theCase) {
        setCaseExists(false);
        return null;
      }
    })().catch((err) => console.log(err));
  }, []);

  // Get consumer and holding data
  const {
    //identityData,
    consumerPersonaData,
  } = useConsumerProfile(consumerId);
  const { getSingleHolding, getSingleHoldingProxy } =
    useGetConsumerHoldings(consumerHoldingId);

  if (!caseFlowCase || !caseActorContext) return <LoadingSpinner size="md" />;

  // Get all the relevant case data
  const caseState = caseFlowCase?.GetCaseState();
  const caseActionsStation = caseFlowCase?.GetPossibleActionsInStation();
  const caseActionsAll = caseFlowCase?.GetPossibleActions();
  const caseActionsGlobal = caseFlowCase?.GetGlobalActions();
  const chatLog = caseFlowCase?.GetAllChat();
  const caseActors = caseFlowCase?.GetActors();
  const caseUpdates = caseFlowCase?.GetMergedGazette();
  const caseAlterability = caseFlowCase?.GetAlterability();
  const caseTimeCreated = caseFlowCase?.GetTimeCreated();
  const caseTimeUpdated = caseFlowCase?.GetTimeUpdated();
  const caseStateHistory = caseFlowCase?.GetStateHistory();
  const caseSpotlight = caseFlowCase?.GetCaseSpotlight();

  const executeAction = async (
    action: string,
    params?: { [key: string]: any }
  ) => {
    await caseFlowCase?.Alter_WithAction(action, params);
    await caseFlowCase?.Commit();
  };

  const alterCaseFacts = async (facts: { [key: string]: any }) => {
    await CaseFlow_Utils_AlterCaseFacts(caseFlowCase, facts);
  };

  const alterCasePublicEmail = async (emailParams: GazetteEmailParams) => {
    await caseFlowCase?.Alter_PublicEmail(emailParams);
    await caseFlowCase?.Commit();
  };

  // Chat functions and data
  const sendChatMessage = async (
    actor: keyof OneEachOptionalPersonaLink,
    message: string
  ) => {
    await caseFlowCase?.Alter_Chat(message);
    await caseFlowCase?.Commit();
    await caseFlowCase?.UpdateChatLastSeen(actor);
  };

  const getMyChatLastSeen = () => {
    return caseFlowCase?.GetMyChatLastSeen();
  };

  const updateChatLastSeen = (actor: keyof OneEachOptionalPersonaLink) => {
    caseFlowCase?.UpdateChatLastSeen(actor);
  };

  /** Moves the spotlight to the consumer (C), repairer (R) or warrantor (W)  */
  const moveSpotlight = async (target: PersonaTypeSingleLetter | 'NONE') => {
    if (target === PersonaTypeSingleLetter.Consumer) {
      caseFlowCase?.Alter_CaseSpotlight({
        add: caseActors?.consumer,
        clear: true,
      });
    }
    if (target === PersonaTypeSingleLetter.Repairer) {
      caseFlowCase?.Alter_CaseSpotlight({
        add: caseActors?.external_repairer ?? caseActors?.repairer,
        clear: true,
      });
    }
    if (target === PersonaTypeSingleLetter.Warrantor) {
      caseFlowCase?.Alter_CaseSpotlight({
        add: caseActors?.warrantor,
        clear: true,
      });
    }
    if (target === 'NONE') {
      caseFlowCase?.Alter_CaseSpotlight({
        clear: true,
      });
    }
    // await caseFlowCase?.Commit();
  };

  return (
    <CaseflowContext.Provider
      value={{
        caseFlowCase,
        operatingPersona,
        holdingData,
        getHoldingData,
        holdingProxyData,
        consumerPersonaData,
        // consumerIdentityData: identityData,
        modalSettings,
        setModalSettings,
        showModal,
        setShowModal,
        caseFacts,
        caseState,
        caseActionsStation,
        caseActionsAll,
        caseActionsGlobal,
        chatLog,
        caseActors,
        caseActorContext,
        caseUpdates,
        caseAlterability,
        executeAction,
        alterCaseFacts,
        alterCasePublicEmail,
        sendChatMessage,
        caseId,
        operatingPersonaSingleKey,
        moveSpotlight,
        getMyChatLastSeen,
        updateChatLastSeen,
        caseTimeCreated,
        caseTimeUpdated,
        caseExists,
        caseStateHistory,
        caseSpotlight,
      }}
    >
      {children}
    </CaseflowContext.Provider>
  );
};

export { CaseflowContext, CaseflowProviderWrapper };

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */
