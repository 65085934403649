import { DTConsumer_Private } from '@rabbit/data/types';
import { MakeCaseARepairCase } from '@rabbit/bizproc/client';
import {
  CaseFlowCaseEditingID,
  MakeCaseEditingID,
  ReactCaseFlowCase,
} from './react-case-flow-case';
import { CaseFlowRegistration } from '../types';

/** Create a new case flow case object, register it for react editing, and return its temporary ID.
 * The ID can be used to reference it in useCaseFlowCase.
 */
export async function ReactCaseFlow_NewRepairCase(
  consumer_persona: DTConsumer_Private,
  holding_docid: string
) {
  // Make a new case. We set it up with the default repair configuration.
  const cfc = new ReactCaseFlowCase(
    consumer_persona.docid,
    consumer_persona.docid
  ); // BNC: Operator and Principal IDs are passed in separately. In consumer situation our consumer is both, but this will change for large organisation asset management (in the future)
  await MakeCaseARepairCase(cfc, consumer_persona, holding_docid);

  RegisterCaseFlowCaseForEditing(cfc);

  return cfc.GetEditingID();
}

export async function ReactCaseFlow_OpenCaseForEditing(
  operating_persona_id: string,
  principal_persona_id: string,
  case_id: string
) {
  const editingID = MakeCaseEditingID(
    operating_persona_id,
    principal_persona_id,
    case_id
  );

  if (CaseFlowRegistrationCollection[CaseFlowEditingIDToKey(editingID)]) {
    // We are already editing so we will just return with the ID
    return editingID;
  }

  // We must create the case and set it up to edit
  const cfc = new ReactCaseFlowCase(
    operating_persona_id,
    principal_persona_id,
    case_id
  );
  await cfc.LoadCase(case_id);
  RegisterCaseFlowCaseForEditing(cfc);

  return cfc.GetEditingID();
}

export function ReactCaseFlow_OpenAKnownCaseButReturnSynchronously(
  id: CaseFlowCaseEditingID
): ReactCaseFlowCase {
  const key = CaseFlowEditingIDToKey(id);
  const reg = CaseFlowRegistrationCollection[key];
  if (reg) {
    if (reg.type === 'forward') {
      return ReactCaseFlow_OpenAKnownCaseButReturnSynchronously(reg.forward);
    }
    return reg.cfc;
  }

  // We must create the case and set it up to edit
  const cfc = new ReactCaseFlowCase(
    id.operating_persona,
    id.principal_persona,
    id.case,
    id.isAdmin
  );
  RegisterCaseFlowCaseForEditing(cfc);
  if (id?.case) void cfc.LoadCase(id?.case); // Kick off an async load but DONT wait for it

  return cfc;
}

const CaseFlowRegistrationCollection: { [key: string]: CaseFlowRegistration } =
  {};

function CaseFlowEditingIDToKey(id: CaseFlowCaseEditingID): string {
  return `${id.operating_persona}_${id.principal_persona}_${id.case}`;
}

function RegisterCaseFlowCaseForEditing(cfc: ReactCaseFlowCase) {
  const key = CaseFlowEditingIDToKey(cfc.GetEditingID());
  if (CaseFlowRegistrationCollection[key]) {
    throw new Error(`Case flow case with key ${key} already registered`);
  }
  CaseFlowRegistrationCollection[key] = {
    type: 'cfc',
    cfc,
  };
}

export function GetCaseFlowCaseFromEditingID(
  id: CaseFlowCaseEditingID
): ReactCaseFlowCase | null {
  const key = CaseFlowEditingIDToKey(id);
  const reg = CaseFlowRegistrationCollection[key];
  if (!reg) {
    // throw new Error(`Case flow case with key ${key} not registered`);
    return null;
  }
  if (reg.type === 'forward') {
    return GetCaseFlowCaseFromEditingID(reg.forward);
  }
  if (reg.type !== 'cfc') {
    throw new Error(`Case flow case with key ${id} is not a cfc`);
  }
  return reg.cfc;
}
