import {
  DR_Identity,
  DR_Identity_Private,
  DR_Repairer_Private,
  DR_Repairer_Public,
  DR_Retailer_Public,
  DR_Retailer_Private,
  useGetMySagePersonas,
  useIdentity,
  usePortalMultipleDocumentEditor,
} from '@rabbit/data/portal';
import { useFirebaseAuthentication } from '@rabbit/data/portal';
import { Address, PersonaTypeFullKey } from '@rabbit/data/types';
import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { DRDocType } from '@rabbit/firebase/react';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

interface SageProfileFormDataShape {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: number | string | null;
  addresses: Address[];
}

type SingleDocumentSelection<T extends NoSqlDoc> = {
  type: DRDocType<T>;
  docid: string;
};

/**
 * Used on Sage. This hook is used to provide functions for updating an enterprise user profile.
 * For now, only repairers will be supported.
 */
export function useManageSageProfile() {
  const identity = useIdentity();
  if (!identity) throw new Error('No identity found');

  const {
    //manufacturer,
    repairerPersona,
    retailerPersona,
  } = useGetMySagePersonas();
  const { updateUserPassword, fbAuthCurrentUser, updateUserProfile } =
    useFirebaseAuthentication();

  const userActivePersonas = [] as PersonaTypeFullKey[];

  const portalDocIds = {
    identity: {
      type: DR_Identity,
      docid: identity.uid || '',
    },
    identity_private: {
      type: DR_Identity_Private,
      docid: identity.uid || '',
    },
  } as Record<string, SingleDocumentSelection<any>>;

  if (repairerPersona?.personaId) {
    portalDocIds.repairer_public = {
      type: DR_Repairer_Public,
      docid: repairerPersona.personaId,
    };
    portalDocIds.repairer_private = {
      type: DR_Repairer_Private,
      docid: repairerPersona.personaId,
    };
    userActivePersonas.push('repairer');
  }

  if (retailerPersona?.personaId) {
    portalDocIds.retailer_public = {
      type: DR_Retailer_Public,
      docid: retailerPersona.personaId,
    };
    portalDocIds.retailer_private = {
      type: DR_Retailer_Private,
      docid: retailerPersona.personaId,
    };
    userActivePersonas.push('retailer');
  }

  const { body, status, isReady, commit, update } =
    usePortalMultipleDocumentEditor(portalDocIds);

  /* -------------------------------------------------------------------------- */
  /*                              Password updates                              */
  /* -------------------------------------------------------------------------- */
  const updatePassword = async (oldPass: string, newPass: string) => {
    if (!fbAuthCurrentUser?.email) throw new Error('No user is logged in');
    // First reauthenticate the user, to make sure the change goes through;
    const credential = EmailAuthProvider.credential(
      fbAuthCurrentUser.email,
      oldPass
    );

    const res = await reauthenticateWithCredential(
      fbAuthCurrentUser,
      credential
    );

    if (res) {
      try {
        await updateUserPassword(newPass);
      } catch (err) {
        throw new Error(`Error updating password: ${err}`);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Profile updates                              */
  /* -------------------------------------------------------------------------- */

  const updateProfileData = async (formData: SageProfileFormDataShape) => {
    if (!repairerPersona?.personaId && !retailerPersona?.personaId)
      throw new Error('A valid persona is required');

    // note: email will be temporarily blocked to editing as it is the main account identifier -dc
    const { first_name, last_name, email, phone_number, addresses } = formData;

    //Update firebase auth profile
    await updateUserProfile(
      generateUserName(first_name, last_name, body.identity.displayname)
    );

    const {
      identity: prevIdentity,
      identity_private: prevIdentityPrivate,
      repairer_public: prevRepairerPublic,
      repairer_private: prevRepairerPrivate,
      retailer_private: prevRetailerPrivate,
      retailer_public: prevRetailerPublic,
    } = body;

    const identityUpdates = generateUpdateObj(
      'identity',
      prevIdentity,
      prevIdentityPrivate,
      formData
    );

    const repairerUpdates = generateUpdateObj(
      'repairer',
      prevRepairerPublic,
      prevRepairerPrivate,
      formData
    );

    const retailerUpdates = generateUpdateObj(
      'retailer',
      prevRetailerPublic,
      prevRetailerPrivate,
      formData
    );

    const updateObj = {
      ...identityUpdates,
      ...repairerUpdates,
      ...retailerUpdates,
    };

    // Update personas
    if (isReady) {
      try {
        await update(updateObj);
        await commit();
      } catch (err) {
        throw new Error(`Error updating profile: ${err}`);
      }
    }
  };

  const updateLanguageCode = async (languageCode: string) => {
    if (!repairerPersona?.personaId && !retailerPersona?.personaId)
      throw new Error('A valid  persona is required');
    const {
      repairer_public: prevRepairerPublic,
      repairer_private: prevRepairerPrivate,
      identity: prevIdentity,
      identity_private: prevIdentityPrivate,
      retailer_private: prevRetailerPrivate,
      retailer_public: prevRetailerPublic,
    } = body;

    const updateObj = {
      identity: {
        ...prevIdentity,
        languageCode: languageCode,
      },
      identity_private: {
        ...prevIdentityPrivate,
        languageCode: languageCode,
      },
    } as any;

    if (prevRepairerPublic) {
      updateObj.repairer_public = {
        ...prevRepairerPublic,
      };
      updateObj.repairer_private = {
        ...prevRepairerPrivate,
      };
    }

    if (prevRetailerPublic) {
      updateObj.retailer_public = {
        ...prevRetailerPublic,
      };
      updateObj.retailer_private = {
        ...prevRetailerPrivate,
      };
    }
    if (isReady) {
      try {
        await update(updateObj);
        await commit();
      } catch (err) {
        throw new Error(`Error updating profile image: ${err}`);
      }
    }
  };

  const updateProfileImage = async (url: string) => {
    if (!repairerPersona?.personaId && !retailerPersona?.personaId)
      throw new Error('A valid  persona is required');

    const {
      repairer_public: prevRepairerPublic,
      repairer_private: prevRepairerPrivate,
      identity: prevIdentity,
      identity_private: prevIdentityPrivate,
      retailer_private: prevRetailerPrivate,
      retailer_public: prevRetailerPublic,
    } = body;

    // TODO: This is a temporary fix to allow the user to update their profile image -VP
    // if (!prevRepairerPublic || !prevRepairerPrivate)
    //   throw new Error('Invalid data');

    const updateObj = {
      identity: {
        ...prevIdentity,
      },
      identity_private: {
        ...prevIdentityPrivate,
      },
    } as any;

    if (prevRepairerPublic) {
      updateObj.repairer_public = {
        ...prevRepairerPublic,
        photoUrl: url,
      };
      updateObj.repairer_private = {
        ...prevRepairerPrivate,
      };
    }

    if (prevRetailerPublic) {
      updateObj.retailer_public = {
        ...prevRetailerPublic,
        photoUrl: url,
      };
      updateObj.retailer_private = {
        ...prevRetailerPrivate,
      };
    }

    // Update personas
    if (isReady) {
      try {
        await update(updateObj);
        await commit();
      } catch (err) {
        throw new Error(`Error updating profile image: ${err}`);
      }
    }
  };
  // Much more to come in the future
  return {
    identity,
    userActivePersonas,
    currentData: body,
    readyToUpdate: isReady,
    updatePassword,
    updateProfileData,
    updateProfileImage,
    updateLanguageCode,
  };
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const generateUserName = (
  first_name: string,
  last_name: string,
  existing_name: string
) => {
  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }

  if (first_name) {
    return first_name;
  }

  if (existing_name) return existing_name;
  return '';
};

const generateUpdateObj = (
  type: 'identity' | 'repairer' | 'retailer',
  prevPublic: any,
  prevPrivate: any,
  data: any
) => {
  if (type === 'identity')
    return {
      identity: {
        ...prevPublic,
        displayname: generateUserName(
          data.first_name,
          data.last_name,
          prevPublic.displayname
        ),
      },
      identity_private: {
        ...prevPrivate,
        displayname: generateUserName(
          data.first_name,
          data.last_name,
          prevPublic.displayname
        ),
      },
    };

  return {
    [`${type}_public`]: {
      ...prevPublic,
      name: generateUserName(data.first_name, data.last_name, prevPublic?.name),
      addresses: data.addresses || prevPublic.addresses || [],
      phone: String(data.phone_number) ?? prevPublic.phone ?? '',
      // email: email ?? prevPublic.email ?? '',
    },
    [`${type}_private`]: {
      ...prevPrivate,
    },
  };
};
