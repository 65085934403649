import { z, schema } from '@rabbit/utils/ts';
import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { Address, SplitName, Z_Address, Z_SplitName } from '../base/basic';
import {
  DTPersona,
  DTPersonaPublic,
  FBD_Keygen_Persona,
  PersonaLink,
  Z_DTPersona,
  Z_DTPersonaPublic,
} from './persona';
import { HoldingLink } from '../lib/holding';


export interface Holding {
  /** holds holdingLink and mfr */
  holdingLink: HoldingLink;
  mfr?: PersonaLink
}
export interface DTConsumer_Private extends DTPersona {
  /** Full name, used in search */
  fullname: string;

  /** Split Name, for when it's a person with first/last name */
  splitname?: SplitName;

  /** Location of the consumer */
  address?: Address[];

  /** Phone number */
  phone?: string;

  /** Email address */
  email?: string;

  /** Gender */
  gender?: string;

  /** type_of_dwelling */
  type_of_dwelling?: string;

  /** living_situation */
  living_situation?: string;

  /** work_status */
  work_status?: string;

  /** Url to Photo */
  photoUrl?: string;

  /** Preferred contact method */
  preferred_contact?: string[];

  /** Timestamp of last accepted terms */
  acceptedTerms: number;

  /** Timestamp of Date of Birth */
  dob: number;

  /** If false, display onboarding flow on user login */
  isOnboarded: boolean;

  /** If false, display modal on barcode scanner with info on how to use it */
  hasUsedScanner: boolean;

  /** Boolean to accept or not promotional emails */
  marketing: boolean;

  /** Array of holding IDs belonging to this consumer */
  holdings: Holding[];

  /** Array of holding IDs that were self-registered by this consumer */
  srv_holdings: Holding[];

  /** If this consumer will use their holdings for commercial applications or not.
   * Can have an impact on the type of warranties they can access.
   */
  isCommercialUser?: boolean;
}

/** Public version of the consumer, used for search */
export interface DTConsumer_Public extends DTPersonaPublic {
  /** Full name, used in search */
  fullname: string;

  /** Split Name, for when it's a person with first/last name */
  splitname?: SplitName;

  /** Location of the consumer */
  address?: Address[];

  /** Phone number */
  phone?: string;

  /** Email address */
  email?: string;

  /** Gender */
  gender?: string;

  /** type_of_dwelling */
  type_of_dwelling?: string;

  /** living_situation */
  living_situation?: string;

  /** work_status */
  work_status?: string;

  /** Url to Photo */
  photoUrl?: string;

  /** Preferred contact method */
  preferred_contact?: string[];

  /** Timestamp of last accepted terms */
  acceptedTerms: number;

  /** Timestamp of Date of Birth */
  dob: number;

  /** If false, display onboarding flow on user login */
  isOnboarded: boolean;

  /** If false, display modal on barcode scanner with info on how to use it */
  hasUsedScanner: boolean;

  /** Boolean to accept or not promotional emails */
  marketing: boolean;

  /** Array of holding IDs belonging to this consumer */
  holdings: Holding[];

  /** Array of holding IDs that were self-registered by this consumer */
  srv_holdings: Holding[];
}

export const Z_DTConsumer_Private = schema<DTConsumer_Private>()(
  z
    .object({
      fullname: z.string(),
      splitname: Z_SplitName.optional(),
      address: z.array(Z_Address).optional(),
      phone: z.string().optional(),
      email: z.string().optional(),
      gender: z.string().optional(),
      type_of_dwelling: z.string().optional(),
      living_situation: z.string().optional(),
      work_status: z.string().optional(),
      photoUrl: z.string().optional(),
      preferred_contact: z.array(z.string()).optional(),
      acceptedTerms: z.number(),
      dob: z.number(),
      isOnboarded: z.boolean(),
      hasUsedScanner: z.boolean(),
      marketing: z.boolean(),
      holdings: z.array(z.string()),
      srv_holdings: z.array(z.string()),
      isCommercialUser: z.boolean().optional(),
    })
    .merge(Z_DTPersona)
);

export const Z_DTConsumer_Public = schema<DTConsumer_Public>()(
  z
    .object({
      fullname: z.string(),
      splitname: Z_SplitName.optional(),
      address: Z_Address.optional(),
      photoUrl: z.string().optional(),
    })
    .merge(Z_DTPersonaPublic)
);

export type ConsumerLink = string;
export const Z_ConsumerLink = z.string();

export const FBD_Consumer_Private = MakeFBDocType<DTConsumer_Private>({
  name: 'Consumer_Private',
  collection: 'consumer_private',
  empty: () => {
    const result: DTConsumer_Private = {
      ...Empty_NoSqlDoc(),
      fullname: '',
      delegates: {},
      identity: '',
      acceptedTerms: 0,
      dob: 0,
      isOnboarded: false,
      hasUsedScanner: false,
      marketing: false,
      holdings: [],
      srv_holdings: [],
      address: [],
      createTime: new Date().getTime(),
    };
    return result;
  },
  keygen: FBD_Keygen_Persona('C'),
});

export const FBD_Consumer_Public = MakeFBDocType<DTConsumer_Public>({
  name: 'Consumer_Public',
  collection: 'consumer_public',
  empty: () => {
    const result: DTConsumer_Public = {
      ...Empty_NoSqlDoc(),
      fullname: '',
      address: [],
      acceptedTerms: 0,
      dob: 0,
      isOnboarded: false,
      hasUsedScanner: false,
      marketing: false,
      holdings: [],
      srv_holdings: [],
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});
