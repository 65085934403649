import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  CLAIM_STATUS_OPTIONS_FATBIKES,
  ReactCaseFlowCase,
} from '@rabbit/bizproc/react';
import { CaseFlowFacts } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { OurDateTime } from '@rabbit/utils/ts';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import { toast } from 'react-toastify';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { ClaimCaseEmailNotification } from 'apps/sage/src/utils/ClaimEmailNotification';
import { CaseFlowCaseStations } from '@rabbit/bizproc/core';

export interface UpdateClaimDetailsFormProps {
  claimDetails: any; // todo type
  handleClose: () => void;
  caseFlowCase: ReactCaseFlowCase;
  setLoading: Dispatch<SetStateAction<boolean>>;
  vendableLabel: string;
  faultOptions: {
    id: string;
    value: string;
    label: string;
    name: string;
  }[];
  consumerIssueTypeOptions: {
    id: string;
    value: string;
    label: string;
  }[];
}

interface FormValuesShape {
  holding_vendable_id: string;
  purchase_date: number | Date;
  status: string;
  holding_warranty_term: number;
  consumer_issue_description: string;
  consumer_issue_type_ref: string;
}

export const validationSchema = Yup.object().shape({
  holding_vendable_id: Yup.string().trim(),
  purchase_date: Yup.mixed(),
  status: Yup.string(),
  holding_warranty_term: Yup.number(),
  consumer_issue_description: Yup.string().trim(),
  consumer_issue_type_ref: Yup.string().trim(),
});

// Todo extract common elements with other such forms to a shared location

export function UpdateClaimDetailsFormType1({
  claimDetails,
  handleClose,
  caseFlowCase,
  setLoading,
  vendableLabel,
  consumerIssueTypeOptions,
}: UpdateClaimDetailsFormProps) {
  const { t } = useTranslation();
  const {
    caseAlterability,
    alterCaseFacts,
    executeAction,
    alterCasePublicEmail,
  } = useContext(CaseflowContext) || ({} as CaseflowInterface);
  const appInfo = useAppInfo();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateSubmitting = (state: boolean) => {
    setIsSubmitting(state);
    setLoading(state);
  };

  /* ----------------------- Set initial values to form ----------------------- */

  const initialValues = {
    holding_vendable_id:
      (claimDetails && claimDetails.holding_vendable_id) || '',
    purchase_date: claimDetails?.purchase_date
      ? OurDateTime.timestampToDate(claimDetails?.purchase_date)
      : 0,
    holding_warranty_term:
      claimDetails?.holding_warranty_term?.amount ?? undefined,
    status: caseFlowCase.GetCaseState(),
    consumer_issue_description: claimDetails?.consumer_issue_description || '',
    consumer_issue_type_ref: claimDetails?.consumer_issue_type_ref || '',
  };

  /* -------------------------------- On Submit ------------------------------- */
  async function onSubmit(values: FormValuesShape) {
    updateSubmitting(true);

    updateClaimDetails({
      ...values,
      consumer_issue_type:
        consumerIssueTypeOptions.find(
          (option) => option.id === values.consumer_issue_type_ref
        )?.label ?? '',
    });
  }

  async function updateClaimDetails(
    formData: FormValuesShape & { consumer_issue_type: string }
  ) {
    if (caseAlterability !== 'FreeToAlter') return;

    const {
      status,
      consumer_issue_description,
      consumer_issue_type,
      consumer_issue_type_ref,
    } = formData;

    // Let's prepare the data to update both the case and the consumer/holding documents

    let factUpdates: CaseFlowFacts = {
      consumer_issue_description,
      consumer_issue_type,
      consumer_issue_type_ref,
    };

    /* ---------------------------- Update case state --------------------------- */
    try {
      if (status && status !== caseFlowCase.GetCaseState()) {
        // If the status is moved to preliminary assessment, we need to reset the repairer and the invoices
        if (status === CaseFlowCaseStations.PRELIMINARY_ASSESSMENT) {
          executeAction &&
            (await executeAction('assign_case', {
              delegate_repairer_id: 'Unassigned',
              delegate_repairer_name: 'Unassigned',
            }));
          factUpdates = {
            ...factUpdates,
            external_repair_invoices: [],
          };
        }
        executeAction && (await executeAction(`move_to_${status}`));
      }

      alterCaseFacts && (await alterCaseFacts(factUpdates));
      await ClaimCaseEmailNotification({
        status,
        caseFlowCase,
        appInfo,
        alterCasePublicEmail,
        t,
      });
      toast.success(t('message.claimDetailsUpdatedSuccessfully'));
      updateSubmitting(false);
      handleClose();
    } catch (err) {
      setIsSubmitting(false);
      toast.error(t('message.failedToUpdateClaimDetails'));
      console.log(err);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors }) => {
        return (
          <Form>
            <div className="flex flex-col gap-4">
              <Input
                type="select"
                label={t('general.claimStatus')}
                name="status"
                settings={{
                  options: CLAIM_STATUS_OPTIONS_FATBIKES(t),
                  id: 'status',
                  placeholder: t('message.pleaseSelectAnOption'),
                }}
              />
              <hr className="my-4 border-t border-gray-200" />

              <div className="flex items-start gap-4">
                <div className="w-1/3 shrink-0 grow">
                  <Input
                    type="autocomplete-vendable"
                    name="holding_vendable_id"
                    label={t('general.product')}
                    settings={{
                      isMulti: false,
                      id: 'holding_vendable_id',
                      placeholder: vendableLabel,
                      options: [],
                      errors: errors,
                      tenantLink: t('tenantLink'),
                      disabled: true,
                    }}
                  />
                </div>
              </div>
              <div className="flex items-end gap-4">
                <Input
                  type="datepicker"
                  name="purchase_date"
                  label={t('general.dateOfPurchase')}
                  settings={{
                    id: 'purchase_date',
                    maxDate: new Date(),
                    placeholder: t('Date of purchase'),
                    disabled: true,
                  }}
                />
                <Input
                  type="warranty_term"
                  name="holding_warranty_term"
                  label={t('general.warrantyTerm')}
                  settings={{
                    id: 'holding_warranty_term',
                    placeholder: '',
                    disabled: true,
                  }}
                />
              </div>
              <hr className="my-4 border-t border-gray-200" />
              {/*<div className="flex items-start gap-4">
                <Input
                  type="select"
                  label={t('Faults')}
                  name="holding_faults"
                  settings={{
                    options: faultOptions,
                    isMulti: true,
                    id: 'holding_faults',
                    errors: errors,
                  }}
                  />
                  </div>*/}
              <div className="flex items-start">
                <Input
                  type="select"
                  name="consumer_issue_type_ref"
                  label={t('general.customerIssueType')}
                  settings={{
                    isMulti: false,
                    id: 'consumer_issue_type_ref',
                    placeholder: t('Please select'),
                    options: consumerIssueTypeOptions,
                    errors: errors,
                  }}
                />
              </div>
              <div className="flex w-full flex-col">
                <Input
                  type="rich-text"
                  name="consumer_issue_description"
                  label={t('general.customerIssueDescription')}
                  settings={{
                    id: 'consumer_issue_description',
                    placeholder: t('Write text here...'),
                    allowSpecialCharacter: true,
                    disabled: claimDetails?.consumer_issue_description
                      ? true
                      : false,
                  }}
                />
              </div>
              {/*<div className="mb-2 grid grid-cols-2 items-end gap-4">
                <Input
                  type="select"
                  label={t('Preliminary assessment')}
                  name="preliminary_assessment"
                  settings={{
                    options: COVERAGE_OPTIONS_FATBIKES,
                    placeholder: t('Please select an option'),
                    id: 'preliminary_assessment',
                    errors: errors,
                  }}
                />
                <Input
                  type="select"
                  label={t('Final assessment')}
                  name="final_assessment"
                  settings={{
                    options: COVERAGE_OPTIONS_FATBIKES,
                    placeholder: t('Please select an option'),
                    id: 'final_assessment',
                    errors: errors,
                  }}
                />
                </div>*/}
              <div className="grid grid-cols-2 gap-4">
                <Button
                  kind="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={errors ? false : true}
                  className="bg-primary-600"
                >
                  {t('general.updateClaimDetails')}
                </Button>
                <Button
                  kind="red"
                  type="button"
                  loading={isSubmitting}
                  onClick={handleClose}
                >
                  {t('general.cancel')}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default UpdateClaimDetailsFormType1;
