import {
  DelegationMapping,
  DTPersona,
  GetAnonymousPrivateFBDForPersona,
  Permissions,
} from '@rabbit/data/types';

// TODO: These functions should probably all be run as cloud functions since they need secure access to things that we don't give general users access to.

export async function Security_RequestDelegateAccessToPrincipal(
  myId: string,
  principalId: string,
  access: Permissions[]
) {
  // TODO: This function will actually set some cookie magic to go through security. It's not done yet so we just validate for now.
  // TODO: For now, there is no inheritance and the delegate MUST be mentioned by the Principal.
  // TODO: Some deeper testing of this - probably make a security unit test to try all the fail state possibilities.

  // Get the principal
  const principalType = GetAnonymousPrivateFBDForPersona(principalId);
  const principalDoc = await principalType.get(principalId);
  if (!principalDoc) {
    throw new Error(
      `Security_RequestDelegateAccessToPrincipal: Principal doc ${principalId} not found.`
    );
  }

  // Check if we are mentioned as a delegate.
  if (principalDoc.delegates && principalDoc.delegates[myId]) {
    // found one!
    if (access.every((a) => principalDoc.delegates[myId].includes(a))) {
      // we have access!
      return true;
    }
  }
  throw new Error(
    `Security_RequestDelegateAccessToPrincipal: Principal doc [${principalId}] does not mention us [${myId}] as a delegate for permissions [${access}].`
  );
}

/* -------------------------------------------------------------------------- */
/*                          Find principals and peers                         */
/* -------------------------------------------------------------------------- */

export async function Security_FindPrincipals(
  delegate: string
): Promise<DelegationMapping> {
  const result: DelegationMapping = {};
  const delegateType = GetAnonymousPrivateFBDForPersona(delegate);
  // Walk up the tree and find all the principals.
  for await (const node of delegateType.PolyTreeIterateParents(delegate)) {
    if (node.delegates && node.delegates[delegate]) {
      // found one!
      result[node.docid] = node.delegates[delegate];
    }
  }

  return result;
}

export async function Security_FindPeers(
  delegate: string,
  access: Permissions[]
): Promise<string[]> {
  // To do this, we find our principals and then see what peers also have the same access.
  const found: { [key: string]: 1 } = {};

  const delegateType = GetAnonymousPrivateFBDForPersona(delegate);
  // Walk up the tree and find all the principals.
  for await (const node of delegateType.PolyTreeIterateParents(delegate)) {
    if (node.delegates && node.delegates[delegate]) {
      // found one! We are a delegate here so let's see who else is.
      Object.keys(node.delegates).forEach((d) => {
        if (d === delegate) return;
        if (access.every((a) => node.delegates[d].includes(a))) {
          found[d] = 1;
        }
      });
    }
  }
  return Object.keys(found);
}

export async function Security_FindDirectChildrenWithAccess(
  node: DTPersona,
  access: Permissions[]
): Promise<string[]> {
  const delegate = node.docid;
  // To do this, we find children that have the same access.
  const found: { [key: string]: 1 } = {};

  if (node.delegates && node.delegates[delegate]) {
    // found one! We are a delegate here so let's see who else is.
    Object.keys(node.delegates).forEach((d) => {
      if (d === delegate) return;
      if (access.every((a) => node.delegates[d].includes(a))) {
        found[d] = 1;
      }
    });
  }
  return Object.keys(found);
}
