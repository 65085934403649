import React, { useEffect, useState } from 'react';

import { GenericLexicon } from '@rabbit/mixmaster/core';
import { BootI18N, BootLexicon } from './language';

export const MixMasterWrapper = (props: {
  children: React.ReactNode;
  bootstrap: GenericLexicon;
}) => {
  const [bootWait, setBootWait] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const asyncBoot = async () => {
      const configData = await BootLexicon(props.bootstrap);
      await BootI18N(props.bootstrap, configData);
      setBootWait(false);
    };
    if (isLoading) {
      setIsLoading(false);
      void asyncBoot();
    }
  });

  if (bootWait || isLoading) return null;
  return <>{props.children} </>;
};
